import Vue from 'vue';
import Vuex from 'vuex';
import xdvideo from './xdvideo';
import i18n from '../locales';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        xdvideo,
    },
    state: {
        locale: i18n.locale,
        fallbackLocale: i18n.fallbackLocale,
        area:
            location.origin.indexOf('eu.ragnaroketernallove.com') > -1
                ? 'EU'
                : location.origin.indexOf('sea.ragnaroketernallove.com') > -1
                ? 'SEA'
                : 'NA',
    },
    getters: {
        availableLocales: (state) =>
            i18n.t(`support.${state.area}`, state.fallbackLocale),
        isValidLocale: (state, { availableLocales }) => (locale) =>
            locale &&
            availableLocales.find((o) => o.path === locale) !== undefined,
        path: (state, getters) =>
            getters.availableLocales.find((o) => o.locale === state.locale)[
                'path'
            ],
        getLocaleByPath: (state, getters) => (path) => {
            let obj = getters.availableLocales.find((o) => o.path === path);
            return obj ? obj.locale : false;
        },
        getImagePath: (state, getters) => (path, locale) => {
            if (!path) return false;
            if (getters.isValidLocale(locale)) {
                path = i18n.t('folder', locale);
            }
            return require('@/assets/imgs/' + path);
        },
        getLocaleImagePath: (state, getters) => (path) => {
            return getters.getImagePath(path, state.locale);
        },
    },
    mutations: {
        setLocale(state, payload) {
            state.locale = payload;
            i18n.locale = payload;
        },
    },
    actions: {
        setLocale({ state, getters, commit }, path) {
            let locale = getters.getLocaleByPath(path);
            if (locale) {
                commit('setLocale', locale);
            } else {
                commit('setLocale', state.fallbackLocale);
            }
        },
    },
});
export default store;
