<template>
    <part class="section-8">
        <div class="bg-top"></div>
        <div class="content">
            <div class="text">
                {{ $t('s8.content') }}
            </div>
        </div>
        <download-group></download-group>
    </part>
</template>
<script>
import Part from '@/components/Part.vue';
import DownloadGroup from '@/components/DownloadGroup';

export default {
    name: 'S6',
    components: {
        Part,
        DownloadGroup,
    },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/mixin';
.section-8 {
    padding-top: 836px;
    padding-bottom: 80px;
    box-sizing: border-box;
    @include bg(
        $name: 'home/s8/bg.jpg',
        $name-retina: 'home/s8/bg@2x.jpg',
        $size: auto 1101px,
        $y: 130px,
        $color: #314568
    );
    .bg-top {
        position: absolute;
        width: 100%;
        height: 322px;
        left: 0;
        top: 0;
        @include bg(
            $name: 'home/s8/bg-top.png',
            $name-retina: 'home/s8/bg-top@2x.png',
            $size: auto 100%
        );
    }
    .content {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: 986px;
        padding-top: 463px;
        margin: 0 auto;
        font-size: 18px;
        line-height: 21px;
        @include bg(
            $name: 'home/s8/bg-mid.png',
            $name-retina: 'home/s8/bg-mid@2x.png',
            $size: auto 100%
        );
        .text {
            display: flex;
            align-items: center;
            text-align: center;
            height: 150px;
            max-width: 740px;
            margin: 0 auto;
        }
    }
    .download-group {
        margin-top: -136px;
        position: relative;
    }
}
@media (max-width: 768px) {
    .section-8 {
        padding-top: 230px;
        padding-bottom: 60px;
        @include bg(
            $name: 'home/s8/bg.jpg',
            $size: auto 445px,
            $y: 32px,
            $color: #314568
        );
        .bg-top {
            height: 180px;
            @include bg(
                $name: 'home/s8/bg-top-768.png',
                $name-retina: 'home/s8/bg-top-768@2x.png',
                $size: auto 100%
            );
        }
        .content {
            height: 514px;
            padding-top: 250px;
            @include bg(
                $name: 'home/s8/bg-mid-768.png',
                $name-retina: 'home/s8/bg-mid-768@2x.png',
                $size: auto 100%
            );
            .text {
                display: flex;
                align-items: center;
                text-align: center;
                height: 150px;
                max-width: 520px;
            }
        }
        .download-group {
            margin-top: 12px;
        }
    }
}
@media (max-width: 480px) {
    .section-8 {
        padding-top: 128px;
        @include bg(
            $name: 'home/s8/bg-414.jpg',
            $size: auto 278px,
            $color: #314568
        );

        .bg-top {
            height: 104px;
            top: -20px;
            @include bg($name: 'home/s8/bg-top-414.png', $size: auto 100%);
        }

        .content {
            height: 343px;
            padding-top: 130px;
            font-size: 12px;
            line-height: 14px;
            @include bg($name: 'home/s8/bg-mid-414.png', $size: auto 100%);
            .text {
                max-width: 320px;
                padding: 0 10px;
                height: 104px;
            }
        }
    }
}
@media (max-width: 414px) {
    .section-8 {
        @include bg(
            $name: 'home/s8/bg-414.jpg',
            $size: auto 240px,
            $color: #314568
        );

        .bg-top {
            height: 90px;
        }

        .content {
            height: 296px;
        }
    }
}
</style>
