<template>
    <part class="section-6">
        <div class="bg"></div>
        <div class="title">{{ $t('s6.title') }}</div>
        <div class="description">
            {{ $t('s6.description') }}
        </div>
        <xd-video
            vid="c93064a8b325399bc088e98b1a1a579c"
            :options="videoOptions"
        ></xd-video>
    </part>
</template>
<script>
import Part from '@/components/Part.vue';
import XdVideo from '@/components/XdVideo.vue';

export default {
    name: 'S6',
    components: {
        Part,
        XdVideo,
    },
    data() {
        return {
            videoOptions: {
                autoplay: true,
                controls: false,
                loop: true,
            },
        };
    },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/mixin';
.section-6 {
    padding-top: 56.25%;
    text-align: center;

    // background-color: #042234;
    .bg {
        position: absolute;
        width: 100%;
        height: 1035px;
        top: -545px;
        left: 0;
        z-index: 1;
        @include bg(
            $name: 'home/s6/bg.png',
            $name-retina: 'home/s6/bg@2x.png',
            $size: auto 100%
        );
    }
    > .title {
        position: absolute;
        width: 100%;
        left: 0;
        top: -76px;
        line-height: 38px;
        font-size: 48px;
        height: 80px;
        font-weight: bold;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    > .description {
        position: absolute;
        width: 100%;
        left: 0;
        top: 10px;
        line-height: 28px;
        font-size: 24px;
        z-index: 1;
    }
    > .video-container {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        bottom: 0;
        background-color: #314568;
    }
}
@media (max-width: 768px) {
    .section-6 {
        .bg {
            height: 408px;
            top: -246px;
            @include bg(
                $name: 'home/s6/bg-768.png',
                $name-retina: 'home/s6/bg-768@2x.png',
                $size: auto 100%
            );
        }
        > .title {
            top: -94px;
        }
        > .description {
            top: -14px;
            font-size: 18px;
            line-height: 21px;
        }
    }
}

@media (max-width: 480px) {
    .section-6 {
        .bg {
            height: 255px;
            top: -134px;
            @include bg($name: 'home/s6/bg-414.png', $size: cover);
        }
        > .title {
            top: -60px;
            font-size: 24px;
            line-height: 20px;
            height: 50px;
        }
        > .description {
            top: -14px;
            font-size: 12px;
            line-height: 14px;
            width: 310px;
            margin-left: -165px;
            left: 50%;
        }
    }
}
@media (max-width: 414px) {
    .section-6 {
        .bg {
            height: 220px;
        }
    }
}
</style>
