export default {
    folder: 'fr',
    language: 'Français',
    s1: {
        vid: 'dd2aec7143c048ac80474b93af87e1d0',
        slogan: '@:folder/home/s1/slogan.png',
        slogan2x: '@:folder/home/s1/slogan@2x.png',
    },
    s2: {
        title: 'Un nouveau départ',
        content:
            "Plongez dans la toute nouvelle aventure de RO 2.0 ! Inscrivez-vous à l'Académie Cryptura, explorez le monde magique d'Ekalachi et découvrez les pouvoirs du Ninja furtif. ",
    },
    s3: {
        title: '@:folder/home/s3/title.png',
        title2x: '@:folder/home/s3/title@2x.png',
    },
    s4: {
        items: [
            {
                title: "Niveau de force jusqu'à120 !",
                content: [
                    'Premier arrêt - Académie de Criatura',
                    "Apprenez tout sur le jeu en seulement 21 jours et passez au niveau 120 en seulement 30 minutes. Obtenez aussi des objets exclusifs de l'Académie de Criatura, comme des costumes et des animaux de compagnie ! ",
                ],
            },
            {
                title: 'Dévoilez le plus sombre des secrets',
                content: [
                    'Entrez à vos risques et périls car les Gardiens ne prennent pas les intrusions à la légère. Après tout, le Palais cache un sombre et profond secret...',
                ],
            },
            {
                title: 'Admirez la Magie',
                content: [
                    'Entrez dans le mystérieux endroit Ekalachi, la maison des Lutins et de nombreuses autres créatures magiques. Quel butin glorieux y trouverez-vous ? Et quels nouveaux mensonges horribles vous attendent ?',
                ],
            },
        ],
    },
    s5: {
        title: 'Entrez dans la peau du Ninja',
        description:
            'Avec une gamme de compétences de ninjutsu mortelles, le Ninja est une expérience de classe de personnage remarquable que vous ne voudrez pas manquer ! ',
        skills: [
            {
                name: 'Invoquer - Ogare',
                type: 'Compétence active',
                description:
                    'Invoquez une Bête de Tonnerre autour de vous, infligeant M.ATK *660% de DMG de Magie du Vent  à 1 unité ennemie dans un rayon de 6 mètres toutes les secondes. La Bête de Tonnerre  dure 9 secondes',
            },
            {
                name: 'Invoquer - Uzumaki Maru',
                type: 'Compétence active',
                description:
                    "Invoquez une grenouille ninja pour frapper à plusieurs reprises toutes les unités ennemies à portée, infligeant M.ATK *1340% de DMG de Magie d'Eau avec 100% de chances de geler les cibles. Les grenouilles ninja infligent 50% de DMG supplémentaires aux unités ennemies à portée de Marsh.",
            },
            {
                name: 'Ninjutsu - Mille ombres',
                type: 'Compétence active',
                description:
                    "Invoquez un Clone des Ombres pour vous aider au combat. Le Clone des Ombres  peut exécuter certaines compétences. Plus le niveau des compétences est élevé, meilleurs sont les attributs du Clone des Ombres . Jusqu'à 2 Clone des Ombres  peuvent exister simultanément et chacun dure 20 secondes.",
            },
            {
                name: 'Invoquer - Serpent',
                type: 'Compétence active',
                description:
                    "Invoquez un Serpent Yamata sous vos pieds pour qu'il s'enroule autour de l'ennemi, infligeant M.ATK *1160% de DMG de Magie de Terre à toutes les unités ennemies situées dans un rayon de 5 mètres par seconde pendant 3 secondes avec 10% de chances de les effrayer pendant 5 secondes.",
            },
            {
                name: 'Sort psychique - Manzomaru Brûlant',
                type: 'Compétence active',
                description:
                    'Invoque le Dragon de Feu pour attaquer la zone désignée. Après une pause, il inflige M.ATK *2880% DMG de Magie de Feu aux ennemis situés dans un rayon de 5 mètres.',
            },
            {
                name: 'Flash Amaterasu',
                type: 'Compétence active',
                description:
                    "Un Ninja se précipite à 5 mètres en avant, infligeant ATK *1800% de DMG physique aux unités ennemies sur son chemin avec 28% de chances de faire saigner les cibles pendant 10 secondes. Il doit être équipé d'une épée.",
            },
        ],
    },
    s6: {
        title: "Amélioration de l'interface utilisateur",
        description:
            "L'interface du jeu a été remaniée pour que votre expérience soit encore meilleure qu'avant !",
    },
    s7: {
        items: [
            {
                title: 'Augmentation du niveau maximum à 170',
                content:
                    'Faites monter les enchères et devenez plus fort avec un nouveau plafond de niveau maximum.',
            },
            {
                title: 'Nouvelle fonctionnalité : Offline Grinding',
                content:
                    "Maintenant, vous pouvez même récolter des objets et de l'EXP lorsque vous êtes en mode AFK (loin de votre clavier)!",
            },
        ],
    },
    s8: {
        content:
            "Selon la légende, les Dieux ont promis à toutes les races de Midgard que quiconque atteindrait le sommet de l'Arbre Divin serait béni. Beaucoup ont essayé d'escalader l'Arbre Divin, mais seul un groupe de lutins tenaces a réussi à atteindre le sommet. Ils reçurent la bénédiction des Dieux pour leur longévité, leur beauté et leurs dons magiques innés, et construisirent ensuite leur maison ici, qu'ils appelèrent Ekalachi.",
    },
    downloadGroup: {
        now: {
            img: '@:folder/home/download-group/btn-download.png',
            img2x: '@:folder/home/download-group/btn-download@2x.png',
            link: {
                SEA: '',
                NA: '',
                EU: '',
            },
        },
        pc: {
            img: '@:folder/home/download-group/btn-pc.png',
            link: {
                SEA:
                    'https://www.ldplayer.net/games/ragnarok-m-eternal-love-eu-on-pc.html',
                NA:
                    'https://www.ldplayer.net/games/ragnarok-m-eternal-love-eu-on-pc.html',
                EU:
                    'https://www.ldplayer.net/games/ragnarok-m-eternal-love-eu-on-pc.html',
            },
        },
        apple: {
            link: {
                SEA:
                    'https://apps.apple.com/ae/app/ragnarok-m-eternal-love-eu/id1468396562',
                NA:
                    'https://apps.apple.com/ae/app/ragnarok-m-eternal-love-eu/id1468396562',
                EU:
                    'https://apps.apple.com/ae/app/ragnarok-m-eternal-love-eu/id1468396562',
            },
        },
        google: {
            link: {
                SEA:
                    'https://play.google.com/store/apps/details?id=com.gravity.romEUg',
                NA:
                    'https://play.google.com/store/apps/details?id=com.gravity.romEUg',
                EU:
                    'https://play.google.com/store/apps/details?id=com.gravity.romEUg',
            },
        },
        taptap: {
            link: {
                SEA: 'https://www.taptap.io/app/175346',
                NA: 'https://www.taptap.io/app/175346',
                EU: 'https://www.taptap.io/app/175346',
            },
        },
    },
    footer: {
        fb: {
            link: {
                SEA: 'https://www.facebook.com/PlayRagnarokMEU.fr/',
                NA: 'https://www.facebook.com/PlayRagnarokMEU.fr/',
                EU: 'https://www.facebook.com/PlayRagnarokMEU.fr/',
            },
        },
        ig: {
            link: {
                SEA: 'https://www.instagram.com/RagnarokMOfficial/',
                NA: 'https://www.instagram.com/RagnarokMOfficial/',
                EU: 'https://www.instagram.com/RagnarokMOfficial/',
            },
        },
        yt: {
            link: {
                SEA: 'https://www.youtube.com/c/RagnarokMEternalLoveOfficial',
                NA: 'https://www.youtube.com/c/RagnarokMEternalLoveOfficial',
                EU: 'https://www.youtube.com/c/RagnarokMEternalLoveOfficial',
            },
        },
        service: {
            text: "Conditions d'utilisation",
        },
        privacy: {
            text: 'Politique de confidentialité',
        },
        notice: {
            text: 'Avis de confidentialité pour les résidents de la Californie',
        },
        cookies: {
            text: 'Politique en matière de cookies',
        },
        terms: {
            text: "Conditions d'utilisation (pour les résidents de l'UE)",
        },
        policy: {
            text:
                "Politique de confidentialité (pour les résidents de l'EEE et du Royaume-Uni)",
        },
    },
};
