<template>
    <part class="section-7">
        <div class="items">
            <div
                class="item"
                v-for="(item, index) in $t('s7.items')"
                :key="index"
            >
                <div class="text">
                    <div class="title">
                        {{ $t('s7.items[' + index + '].title') }}
                    </div>
                    <div class="content">
                        {{ $t('s7.items[' + index + '].content') }}
                    </div>
                </div>
                <img
                    class="image"
                    :src="getImagePath($t(`s7.items[${index}].img`, 'en'))"
                    :srcset="
                        getImagePath($t(`s7.items[${index}].img2x`, 'en')) +
                            ' 2x'
                    "
                    :alt="item.title"
                />
            </div>
        </div>
    </part>
</template>
<script>
import Part from '@/components/Part.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'S7',
    components: {
        Part,
    },
    computed: {
        ...mapGetters(['getImagePath']),
    },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/mixin';
.section-7 {
    background-color: #042234;
    .items {
        display: flex;
        max-width: 1180px;
        margin: 0 auto;
        padding-top: 80px;
        padding-bottom: 30px;
        justify-content: space-around;
        text-align: center;
        flex-wrap: wrap;
        height: 100%;
        .item {
            max-width: 520px;
            margin: 0 35px 40px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .text {
                margin-bottom: 20px;
                .title {
                    margin-bottom: 20px;
                    font-size: 36px;
                    line-height: 46px;
                    font-weight: bold;
                }
                .content {
                    font-size: 18px;
                    line-height: 36px;
                }
            }
            .image {
                width: 100%;
                border: 2px solid rgba(255, 255, 255, 0.4);
                padding: 15px;
                box-sizing: border-box;
            }
        }
    }
}
@media (max-width: 1180px) {
    .section-7 {
        .items {
            padding-top: 70px;
            .item {
                flex-direction: column-reverse;
                margin-left: 50px;
                margin-right: 50px;
                .text {
                    margin-top: 20px;
                    margin-bottom: 0;
                    .title {
                        line-height: 40px;
                    }
                    .content {
                        line-height: 30px;
                    }
                }
            }
        }
    }
}
@media (max-width: 480px) {
    .section-7 {
        .items {
            padding-top: 40px;
            padding-bottom: 0;
            .item {
                .text {
                    margin-top: 10px;
                    .title {
                        font-size: 18px;
                        line-height: 24px;
                        margin-bottom: 10px;
                    }
                    .content {
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
            }
        }
    }
}
</style>
