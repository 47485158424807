export default {
    folder: 'zh',
    language: '中文',
    s1: {
        vid: '61414292d2bea8a868d0fc512a4fa8bd',
        slogan: '@:folder/home/s1/slogan.png',
        slogan2x: '@:folder/home/s1/slogan@2x.png',
    },
    s2: {
        title: '新开始',
        content:
            '沉浸在RO 2.0的全新冒险中! 注册进入王立克里图拉学院，探索艾卡拉奇的魔幻世界，并体验隐身忍者的力量。',
    },
    s3: {
        title: '@:folder/home/s3/title.png',
        title2x: '@:folder/home/s3/title@2x.png',
    },
    s4: {
        items: [
            {
                title: '超速升级至120！',
                content: [
                    '第一站－王立克里图拉学院',
                    '只需21天即可学成有关游戏的所有内容，而仅需30分钟即快速升级到120级。还可以获取克里图拉学院的独家道具，例如服装和宠物！',
                ],
            },
            {
                title: '解开最黑暗的秘密',
                content: [
                    '进入者自担风险，守护者将严正对待擅闯者。毕竟，此宫殿中藏有一个极深极暗的秘密……',
                ],
            },
            {
                title: '对魔法惊叹吧',
                content: [
                    '进入魔幻的艾卡拉奇，浮空精灵和其他魔兽的家园。您在哪里能找到什么样的宝藏呢？会有什么样的恐惧等着您呢？',
                ],
            },
        ],
    },
    s5: {
        title: '忍者莅临',
        description:
            '忍者具有一系列致命的忍法技能，是不容错过的非凡角色体验！ ',
        skills: [
            {
                name: '通灵术·奥加雷',
                type: '主动技',
                description:
                    '召唤一只雷兽环绕自身，每秒对周围6米内1名敌人造成魔法攻击*660%的风属性魔法伤害，雷兽持续9秒。',
            },
            {
                name: '通灵术·漩涡丸',
                type: '主动技',
                description:
                    '召唤一只忍蛙对范围内的敌人进行连续重击，对范围内敌人造成魔法攻击*1340%的水属性魔法伤害，并有100%概率使敌人【冰冻】。忍蛙对【水沼术】内的敌人额外造成50%的伤害。',
            },
            {
                name: '忍法·千本影',
                type: '主动技',
                description:
                    '召唤一个影分身协助战斗，影分身会使用部分技能，技能等级越高，影分身的属性越高。最多存在2个分身，每个影分身持续20秒。',
            },
            {
                name: '通灵术·大蛇',
                type: '主动技',
                description:
                    '在自己脚下召唤一只八岐大蛇，缠绕敌人，每秒对周围5米的敌人造成魔法攻击*1160%的地属性魔法伤害，持续3秒，并有10%概率使其陷入5秒的【恐惧】。',
            },
            {
                name: '通灵术·炎炎万藏丸',
                type: '主动技',
                description:
                    '召唤火龙冲击指定区域，对5米范围内的敌人造成魔法攻击*2880%火属性魔法伤害。',
            },
            {
                name: '天照一闪',
                type: '主动技',
                description:
                    '忍者向前突进5米，对路径上的敌人造成物理攻击*1800%的物理伤害，并有28%概率使敌人陷入【流血】状态，持续10秒。必须装备长剑类武器。',
            },
        ],
    },
    s6: {
        title: '用户界面翻新',
        description: '游戏界面进行了改进，势必让您有更好的游戏体验！',
    },
    s7: {
        items: [
            {
                title: '最高等级上限升至170',
                content: '在新的最高等级上限下，您可以变得更强大。',
            },
            {
                title: '新功能：离线刷怪',
                content: '您甚至能够离线时刷宝藏和经验！',
            },
        ],
    },
    s8: {
        content:
            '传说中，众神答应米德加尔特各种族，只要有任何人能够达到神树顶端，他就能被神祝福。众人试图攀爬神树，但只有顽强的浮空精灵成功达顶。他们受到了众神长生、美丽及魔力与生俱来的祝福，并在树顶建筑家园，称之为艾卡拉奇。',
    },
    downloadGroup: {
        now: {
            img: '@:folder/home/download-group/btn-download.png',
            img2x: '@:folder/home/download-group/btn-download@2x.png',
            link: {
                SEA: 'https://l.tapdb.net/bzrhN7kM',
                NA: 'https://l.tapdb.net/bzrhN7kM',
                EU: 'https://l.tapdb.net/bzrhN7kM',
            },
        },
        pc: {
            img: '@:folder/home/download-group/btn-pc.png',
            link: {
                SEA: '',
                NA: '',
                EU: '',
            },
        },
        apple: {
            link: {
                SEA: 'https://apps.apple.com/app/id1404051022',
                NA: 'https://apps.apple.com/app/id1404051022',
                EU: 'https://apps.apple.com/app/id1404051022',
            },
        },
        google: {
            link: {
                SEA:
                    'https://play.google.com/store/apps/details?id=com.gravity.romg',
                NA:
                    'https://play.google.com/store/apps/details?id=com.gravity.romg',
                EU:
                    'https://play.google.com/store/apps/details?id=com.gravity.romg',
            },
        },
        taptap: {
            link: {
                SEA: 'https://www.tap.io/app/191499',
                NA: 'https://www.tap.io/app/191499',
                EU: 'https://www.tap.io/app/191499',
            },
        },
    },
    footer: {
        fb: {
            link: {
                SEA: 'https://www.facebook.com/PlayRagnarokM.CN',
                NA: 'https://www.facebook.com/PlayRagnarokM.CN',
                EU: 'https://www.facebook.com/PlayRagnarokM.CN',
            },
        },
        ig: {
            link: {
                SEA: 'https://www.instagram.com/RagnarokMOfficial/',
                NA: 'https://www.instagram.com/RagnarokMOfficial/',
                EU: 'https://www.instagram.com/RagnarokMOfficial/',
            },
        },
        yt: {
            link: {
                SEA: 'https://www.youtube.com/c/RagnarokMEternalLoveOfficial',
                NA: 'https://www.youtube.com/c/RagnarokMEternalLoveOfficial',
                EU: 'https://www.youtube.com/c/RagnarokMEternalLoveOfficial',
            },
        },
        service: {
            text: '服务条款',
        },
        privacy: {
            text: '隐私政策',
        },
        notice: {
            text: 'Privacy Notice for California Residents',
        },
        cookies: {
            text: 'Cookies Policy',
        },
        terms: {
            text: 'Terms of Service (For EU Residents)',
        },
        policy: {
            text: 'Privacy Policy  (For EEA and UK Residents)',
        },
    },
};
