export default {
    folder: 'pt',
    language: 'Português',
    s1: {
        vid: 'd10e1a7cc2860b83d8d812e5b4abd6bf',
        slogan: '@:folder/home/s1/slogan.png',
        slogan2x: '@:folder/home/s1/slogan@2x.png',
    },
    s2: {
        title: 'Um Novo Começo',
        content:
            'Imerge na nova aventura de RO 2.0! Inscreva-se na Cryptura Academy, explore o mundo mágico de Eclage e experimente os poderes dos ninjas furtivos. ',
    },
    s3: {
        title: '@:folder/home/s3/title.png',
        title2x: '@:folder/home/s3/title@2x.png',
    },
    s4: {
        items: [
            {
                title: 'Nível de Força Até 120!',
                content: [
                    'Primeira parada - Cryptura Academy. ',
                    'Aprenda tudo sobre o jogo em apenas 21 dias e alcance o nível de força de 120 em apenas 30 minutos. Obtenha itens exclusivos da Cryptura Academy como roupas e animais de estimação também! ',
                ],
            },
            {
                title: 'Descubra o segredo mais obscuro',
                content: [
                    'Entre por sua conta e risco, pois os Guardiões não aceitam a intrusão de forma ligeira. Afinal, o Palácio está escondendo um segredo profundo e obscuro.',
                ],
            },
            {
                title: 'Contemple a Magia',
                content: [
                    'Entre no misterioso Eclage, lar dos Pixies e de muitas outras criaturas mágicas. Que lucro maravilhoso você vai encontrar aqui?  E que novos horrores estão esperando?',
                ],
            },
        ],
    },
    s5: {
        title: 'Entre no Ninja',
        description:
            'Com uma gama de habilidades mortais de ninjutsu, o Ninja é um personagem de classe impressionante que você não vai querer perder! ',
        skills: [
            {
                name: 'Summoning - Ogare',
                type: 'Habilidade Ativa',
                description:
                    'Invoque um Thunder Beast à sua volta, infligindo M.ATK *660% Wind Magic DMG em 1 unidade inimiga dentro de 6 metros a cada segundo. A Thunder Beast dura 9 segundos.',
            },
            {
                name: 'Summoning - Uzumaki Marul',
                type: 'Habilidade Ativa',
                description:
                    'Summon um sapo ninja para dar golpes pesados e contínuos nos inimigos no alcance, causando M.ATK nos inimigos no alcance infligindo o M.ATK *1340% Water Magic DMG com chance de 100 %% Inimigo [Freezing]. O sapo ninja causa 50%% de dano adicional aos inimigos na [Marsh] ',
            },
            {
                name: 'Ninjutsu - Thousand Shadows',
                type: 'Habilidade Ativa',
                description:
                    'Summon um Shadow Clone para ajudar na batalha. O Shadow Clone usará algumas habilidades. Quanto maior o nível de habilidade, maiores os attribute do Shadow Clone Pode-se ter no máximo 2 clones, cada Shadow Clone dura 20 segundos.',
            },
            {
                name: 'Summoning - Serpent',
                type: 'Habilidade Ativa',
                description:
                    'Invoca uma grande serpente Yamata logo abaixo dos seus pés, a qual irá se prender no inimigo e causar M.ATK *1160% Earth Magic Damage em todos os inimigas dentro de 5 metros por segundo durante 3 segundos, com 10% de chance de fazê-las temer por 5 segundos.',
            },
            {
                name: 'Summoning - Scorching Manzomaru',
                type: 'Habilidade Ativa',
                description:
                    'Convocar um Fire Dragon para impactar a área indicada, causando M.ATK *2880% Fire Magic Damage aos inimigos em um raio de 5 m apõs uma pausa.',
            },
            {
                name: 'Amaterasu Flash',
                type: 'Habilidade Ativa',
                description:
                    'Um Ninja corre 5 metros à frente, infligindo ATK *1800% DMG físico em inimigos no caminho com uma chance de 28% de causar o sangramento dos inimigos por 10 segundos. Deve ser equipado uma espada.',
            },
        ],
    },
    s6: {
        title: 'Interface de usuário (UI) melhorada',
        description:
            'A interface do jogo foi remodelada para tornar sua experiência melhor do que antes!',
    },
    s7: {
        items: [
            {
                title: 'Aumento do nível máximo para 170',
                content:
                    'Aumente a aposta e fique mais forte com um novo limite máximo de nível.',
            },
            {
                title: 'Nova característica: Offline Grinding',
                content:
                    'Agora você pode mesmo cultivar para obter saques e EXP quando você estiver longe!',
            },
        ],
    },
    s8: {
        content:
            'Die Legende besagt, dass die Götter alle Rassen in Midgard versprochen haben, dass alle Personen gesegnet werden, die die Spitze des göttlichen Baums erreicht haben. Vielen haben versucht den göttlichen Baum zu besteigen, es hat aber nur eine Gruppe von Kobolden es an die Spitze geschafft. Sie haben den göttlichen Segen der Langlebigkeit, Schönheit und auch magische Geschenke bekommen. So haben sie dann hier ihr Zuhause gebaut und nannten es Eclage.',
    },
    downloadGroup: {
        now: {
            img: '@:folder/home/download-group/btn-download.png',
            img2x: '@:folder/home/download-group/btn-download@2x.png',
            link: {
                SEA: 'https://l.tapdb.net/4zgHlT5z',
                NA: 'https://l.tapdb.net/4zgHlT5z',
                EU: 'https://l.tapdb.net/4zgHlT5z',
            },
        },
        pc: {
            img: '@:folder/home/download-group/btn-pc.png',
            link: {
                SEA:
                    'https://pt.ldplayer.net/games/ragnarok-m-eternal-loverom-on-pc.html',
                NA:
                    'https://pt.ldplayer.net/games/ragnarok-m-eternal-loverom-on-pc.html',
                EU:
                    'https://pt.ldplayer.net/games/ragnarok-m-eternal-loverom-on-pc.html',
            },
        },
        apple: {
            link: {
                SEA: 'https://apps.apple.com/app/id1444739251',
                NA: 'https://apps.apple.com/app/id1444739251',
                EU: 'https://apps.apple.com/app/id1444739251',
            },
        },
        google: {
            link: {
                SEA:
                    'https://play.google.com/store/apps/details?id=com.gravity.romNAg',
                NA:
                    'https://play.google.com/store/apps/details?id=com.gravity.romNAg',
                EU:
                    'https://play.google.com/store/apps/details?id=com.gravity.romNAg',
            },
        },
        taptap: {
            link: {
                SEA: 'https://www.tap.io/app/155376',
                NA: 'https://www.tap.io/app/155376',
                EU: 'https://www.tap.io/app/155376',
            },
        },
    },
    footer: {
        fb: {
            link: {
                SEA: 'https://www.facebook.com/PlayRagnarokMGlobal.ES/',
                NA: 'https://www.facebook.com/PlayRagnarokMGlobal.ES/',
                EU: 'https://www.facebook.com/PlayRagnarokMGlobal.ES/',
            },
        },
        ig: {
            link: {
                SEA: 'https://www.instagram.com/RagnarokMOfficial/',
                NA: 'https://www.instagram.com/RagnarokMOfficial/',
                EU: 'https://www.instagram.com/RagnarokMOfficial/',
            },
        },
        yt: {
            link: {
                SEA: 'https://www.youtube.com/c/RagnarokMEternalLoveOfficial',
                NA: 'https://www.youtube.com/c/RagnarokMEternalLoveOfficial',
                EU: 'https://www.youtube.com/c/RagnarokMEternalLoveOfficial',
            },
        },
        service: {
            text: 'Termos de Serviço',
        },
        privacy: {
            text: 'Política de Privacidade',
        },
        notice: {
            text: 'Privacy Notice for California Residents',
        },
        cookies: {
            text: 'Cookies Policy',
        },
        terms: {
            text: 'Terms of Service (For EU Residents)',
        },
        policy: {
            text: 'Privacy Policy  (For EEA and UK Residents)',
        },
    },
};
