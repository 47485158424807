<template>
    <div class="video-container">
        <div
            class="web-video-xd"
            :data-vid="vid"
            :data-options="opts"
            ref="xdvideo"
        ></div>
    </div>
</template>
<script>
import videojs from '@platform/video-play-sdk';
import '@platform/video-play-sdk/dist/WebVideo.css';
import { mapState } from 'vuex';
export default {
    name: 'XdVideo',
    props: ['vid', 'options', 'isPause'],
    data() {
        return {
            pid: '',
        };
    },
    computed: {
        ...mapState({
            status: (state) => state.xdvideo.status,
            auth: (state) => state.xdvideo.auth,
        }),
        opts: function() {
            return typeof this.options !== 'string'
                ? JSON.stringify(this.options)
                : this.options;
        },
    },
    created: async function() {
        await this.$store.dispatch('xdvideo/asyncGetToken');
    },
    mounted: async function() {
        await this.register();
    },
    watch: {
        status: async function() {
            await this.register();
        },
        isPause: async function(newStatus) {
            if (newStatus) {
                await this.handlePause();
            } else {
                await this.handlePlay();
            }
        },
        vid: async function(newVid) {
            if (this.pid) {
                let player = videojs(this.pid);
                if (player) await player.VideoControl().updateVid(newVid);
            }
        },
    },
    methods: {
        handlePause: async function() {
            if (this.pid) {
                await videojs(this.pid).pause();
            }
        },
        handlePlay: async function() {
            if (this.pid) {
                await videojs(this.pid).play();
            }
        },
        async register() {
            if (this.pid) return;
            if (!this.status) return;
            await videojs.register({
                app_id: this.auth.app_id,
                noncestr: this.auth.noncestr,
                sign: this.auth.sign,
                timestamp: this.auth.timestamp,
                pick: this.$refs.xdvideo,
                onError: function() {},
            });

            this.pid = await videojs.getPid(this.$refs.xdvideo);
            if (!this.isPause) {
                // await this.handlePlay();
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.video-container {
    /deep/ .web-video-xd {
        height: 100%;
        padding-top: 0;
    }
}
</style>
