export default {
    folder: 'th',
    language: 'ไทย',
    s1: {
        vid: 'dbee093c014e63e29ed9a990e878eb2e',
        slogan: '@:folder/home/s1/slogan.png',
        slogan2x: '@:folder/home/s1/slogan@2x.png',
    },
    s2: {
        title: 'การเริ่มต้นครั้งใหม่',
        content:
            'ดื่มด่ำไปกับการผจญภัยครั้งใหม่ใน ROM 2.0! สมัครเข้าเรียนใน Cryptura Academy และออกสำรวจโลกมหัศจรรย์ของ Eclage พร้อมทั้งสัมผัสพลังของคลาสใหม่นินจา',
    },
    s3: {
        title: '@:folder/home/s3/title.png',
        title2x: '@:folder/home/s3/title@2x.png',
    },
    s4: {
        items: [
            {
                title: 'เลเวลอัพสู่ 120!',
                content: [
                    'เริ่มแรก - Cryptura Academy',
                    'เรียนรู้ทุกอย่างเกี่ยวกับเกมในเวลาเพียง 21 วันและยกระดับเลเวลเป็น 120 ในเวลาเพียง 30 นาที รับไอเท็มพิเศษจาก Cryptura Academy เช่นคอสตูมและสัตว์เลี้ยง!',
                ],
            },
            {
                title: 'ก้าวเข้าสู่วิถีของนินจา',
                content: [
                    'หากใจถึงก็ลุยกันได้เลย เพราะพวก Guardians ที่เฝ้าสถานที่แห่งนี้ไม่ต้อนรับผู้บุกรุกที่มาเยือน สุดท้ายแล้วพระราชวังแห่งนี้กำลังซ่อนความลับอันดำมืดอะไรอยู่กันแน่...',
                ],
            },
            {
                title: 'จงสัมผัสพลังเวทย์',
                content: [
                    'เดินทางสู่เมืองลึกลับ Eclage สถานที่ของพิกซี่และสัตว์วิเศษมากมาย คุณจะได้ค้นพบสมบัติล้ำค่าอะไรจากที่นี่?และการเดินทางครั้งนี้มีปริศนาอะไรซ่อนอยู่กันแน่?',
                ],
            },
        ],
    },
    s5: {
        title: 'ก้าวเข้าสู่วิถีของนินจา',
        description:
            'ด้วยทักษะนินจาและวิชานินจูตสึอันยอดเยี่ยม ทำให้คลาสนี้น่าทึ่งและคุณไม่ควรพลาด! ',
        skills: [
            {
                name: 'Summoning - Ogare',
                type: 'Active Skill',
                description:
                    'Restoration อสูรสายฟ้ามาบ้อมรอบตัวเอง ทุกวินาที ทำให้ศัตรูที่อยู่รอบๆ 6 เมตร โดน MAtk*660% MDmg ธาตุ Wind อสูรสายฟ้าต่อเนื่อง 9วินาที',
            },
            {
                name: 'Summoning - Uzumaki Maru',
                type: 'Active Skill',
                description:
                    'Restoration Ninja Frog ทำให้ศัตรูที่อยู่ในขอบเขต Thump ต่อเนื่อง ทำให้ศัตรูที่อยู่รอบๆโดน MAtk*1340% MDmg ธาตุ Water และมีโอกาส 100% ทำให้ศัตรูติดสถานะ [Freezing] Ninja Frog ทำให้[Marsh]ศัตรูที่อยู่ในขอบเขตโดน 50% Dmg',
            },
            {
                name: 'Ninjutsu - Thousand Shadows',
                type: 'Active Skill',
                description:
                    'Restoration Shadow Avatar มาช่วยโจมตี Shadow Avatar จะใช้สกิลบางส่วน Skill Level ยิ่งสูงสถานะของ Shadow Avatar ยิ่งสูง อยู่พร้อมกันได้ 2 Avatar Shadow Avatar แต่ละตัวมีเวลาต่อเนื่อง 20 วินาที',
            },
            {
                name: 'Summoning - Serpent',
                type: 'Active Skill',
                description:
                    'Restoration Yaki Big Snake มาที่ใต้เท้าของตนเอง พันรอบศัตรู ทำให้ศัตรูที่อยู่ในรัศมี 5 เมตรโดน MAtk*1160% MDmg ธาตุ Earth ต่อเนื่อง 3 วินาที และมีโอกาส 10% ติดสถานะ [Fear] 5 วินาที',
            },
            {
                name: 'Summoning - Scorching Manzomaru',
                type: 'Active Skill',
                description:
                    'Restoration Fire Dragon โจมตีพื้นที่ที่กำหนด ทำให้ศัตรูในรัศมี 5 เมตร โดน MDmg*2880% ธาตุ Fire',
            },
            {
                name: 'Amaterasu Flash',
                type: 'Active Skill',
                description:
                    'Ninja พุ่งไปด้านหน้า 5 เมตรทำห็ศัตรูที่อยู่ระหว่างทางโดน Atk*1800% PDmg และมีโอกาส 28% ทำให้ศัตรูติดสถานะ[Bleeding] ต่อเนื่อง 10 วินาที ต้องใส่อาวุธประเภท Long Sword',
            },
        ],
    },
    s6: {
        title: 'ปรับปรุง UI',
        description:
            'อินเทอร์เฟซเกมได้รับการปรับปรุงใหม่เพื่อให้ประสบการณ์ของคุณดีขึ้นกว่าเดิม!',
    },
    s7: {
        items: [
            {
                title: 'เพิ่มเลเวลสูงสุดเป็น 170',
                content: 'เพิ่มพลังและความแข็งแกร่งด้วยเลเวลสูงสุดที่ 170',
            },
            {
                title: 'ระบบใหม่: ฟาร์มออฟไลน์',
                content:
                    'คุณสามารถฟาร์มไอเทมและค่าประสบการณ์ได้เมื่อคุณออฟไลน์อยู่',
            },
        ],
    },
    s8: {
        content:
            'ตำนานได้กล่าวไว้ว่า เทพเจ้าเคยได้ให้สัญญาไว้กับทุกชนเผ่าที่อาศัยอยู่ใน Midgard ว่าชนเผ่าใดก็ตามที่สามารถปีนไปจนถึงยอดต้นไม้ศักดิ์สิทธิ์ Divine Tree จะได้รับพรจากเทพเจ้า หลายเผ่าได้พยายามปีนขึ้นต้นไม้แต่ก็ไปไม่ถึงยอด สุดท้ายมีเพียงเผ่าพิกซีเท่านั้นที่ทำสำเร็จ พวกเขาได้รับพรจากเทพเจ้าตามที่เคยได้ให้สัญญาไว้ การมีอายุยืนยาว ความสวยงามและพลังเวทย์ หลังจากนั้นเป็นต้นมาเหล่าพิกซีก็ได้ลงหลักปักฐานบนยอดต้นไม้นั้นจนกลายมาเป็นอาณาจักร Eclage จนถึงทุกวันนี้',
    },
    downloadGroup: {
        now: {
            img: '@:folder/home/download-group/btn-download.png',
            img2x: '@:folder/home/download-group/btn-download@2x.png',
            link: {
                SEA: 'https://l.tapdb.net/bzrhN7kM',
                NA: 'https://l.tapdb.net/bzrhN7kM',
                EU: 'https://l.tapdb.net/bzrhN7kM',
            },
        },
        pc: {
            img: '@:folder/home/download-group/btn-pc.png',
            link: {
                SEA:
                    'https://th.ldplayer.net/games/ragnarok-m-eternal-love-on-pc.html',
                NA:
                    'https://th.ldplayer.net/games/ragnarok-m-eternal-love-on-pc.html',
                EU:
                    'https://th.ldplayer.net/games/ragnarok-m-eternal-love-on-pc.html',
            },
        },
        apple: {
            link: {
                SEA: 'https://apps.apple.com/app/id1404051022',
                NA: 'https://apps.apple.com/app/id1404051022',
                EU: 'https://apps.apple.com/app/id1404051022',
            },
        },
        google: {
            link: {
                SEA:
                    'https://play.google.com/store/apps/details?id=com.gravity.romg',
                NA:
                    'https://play.google.com/store/apps/details?id=com.gravity.romg',
                EU:
                    'https://play.google.com/store/apps/details?id=com.gravity.romg',
            },
        },
        taptap: {
            link: {
                SEA: 'https://www.tap.io/app/191499',
                NA: 'https://www.tap.io/app/191499',
                EU: 'https://www.tap.io/app/191499',
            },
        },
    },
    footer: {
        fb: {
            link: {
                SEA: 'https://www.facebook.com/PlayRagnarokM.TH',
                NA: 'https://www.facebook.com/PlayRagnarokM.TH',
                EU: 'https://www.facebook.com/PlayRagnarokM.TH',
            },
        },
        ig: {
            link: {
                SEA: 'https://www.instagram.com/RagnarokMOfficial/',
                NA: 'https://www.instagram.com/RagnarokMOfficial/',
                EU: 'https://www.instagram.com/RagnarokMOfficial/',
            },
        },
        yt: {
            link: {
                SEA: 'https://www.youtube.com/c/RagnarokMEternalLoveOfficial',
                NA: 'https://www.youtube.com/c/RagnarokMEternalLoveOfficial',
                EU: 'https://www.youtube.com/c/RagnarokMEternalLoveOfficial',
            },
        },
        service: {
            text: 'เงื่อนไขการให้บริการ',
            link: {
                SEA: 'https://sea.ragnaroketernallove.com/terms-of-service',
                NA: '',
                EU: '/service',
            },
        },
        privacy: {
            text: 'นโยบายความเป็นส่วนตัว',
        },
        notice: {
            text: 'Privacy Notice for California Residents',
        },
        cookies: {
            text: 'Cookies Policy',
        },
        terms: {
            text: 'Terms of Service (For EU Residents)',
        },
        policy: {
            text: 'Privacy Policy  (For EEA and UK Residents)',
        },
    },
};
