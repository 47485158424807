<template>
    <div class="dropdown">
        <div class="btn">
            <svg viewBox="0 0 150 40" fill="none">
                <rect
                    x="0.5"
                    y="0.5"
                    width="149"
                    height="39"
                    rx="4.5"
                    stroke="url(#paint0_linear)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1="75"
                        y1="0"
                        x2="75"
                        y2="40"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="white" />
                        <stop offset="1" stop-color="#96EEF5" />
                    </linearGradient>
                </defs>
            </svg>

            <div class="icon-lang"></div>
            <div class="text">{{ $t('language', locale) }}</div>
            <div class="icon-arrow"></div>
        </div>
        <div class="menu-container">
            <svg :viewBox="'0 0 150 ' + menuHeight" fill="none">
                <rect
                    x="0.5"
                    y="0.5"
                    width="149"
                    :height="menuHeight - 1"
                    rx="4.5"
                    stroke="url(#paint1_linear)"
                />
                <defs>
                    <linearGradient
                        id="paint1_linear"
                        x1="75"
                        y1="0"
                        x2="75"
                        :y2="menuHeight"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="white" />
                        <stop offset="1" stop-color="#96EEF5" />
                    </linearGradient>
                </defs>
            </svg>
            <router-link
                class="menu"
                v-for="obj in availableLocales"
                :key="obj.locale"
                :to="{ name: 'home', params: { path: obj.path } }"
            >
                {{ $t('language', obj.locale) }}
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    name: 'LocaleDropdown',
    props: ['locale', 'availableLocales'],
    computed: {
        menuHeight() {
            return this.availableLocales.length * 40;
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/mixin';
.dropdown {
    position: relative;
    width: 150px;
    opacity: 0.7;
    transition: opacity 0.4s;
    &:hover {
        opacity: 1;
        > .menu-container {
            display: block;
        }
    }
    > .btn,
    > .menu-container {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        border-radius: 5px;
        font-size: 18px;
        background-color: rgba(38, 50, 184, 0.5);
        > svg {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            pointer-events: none;
        }
    }
    > .btn {
        height: 40px;
        display: flex;
        padding: 10px;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        .icon-lang {
            width: 12px;
            height: 12px;
            @include bg($name: 'home/locale-dropdown/lang.svg', $size: 100%);
        }
        .text {
            flex-shrink: 1;
            flex-grow: 1;
            margin: 0 8px;
        }
        .icon-arrow {
            width: 6px;
            height: 6px;
            @include bg($name: 'home/locale-dropdown/arrow.svg', $size: 100%);
        }
    }
    > .menu-container {
        margin-top: 6px;
        display: none;
        overflow: hidden;
        > .menu {
            display: flex;
            width: 100%;
            height: 40px;
            align-items: center;
            box-sizing: border-box;
            padding: 0 30px;
            color: #fff;
            text-decoration: none;
            cursor: pointer;
            &.router-link-active {
                background-color: #13195c;
            }
        }
    }
}
@media (max-width: 414px) {
    .dropdown{
        zoom: .75;
    }
}
</style>
