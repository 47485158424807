<template>
    <part class="section-4">
        <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide v-for="(item, index) in $t('s4.items')" :key="index">
                <div class="image-container">
                    <img
                        class="image"
                        :src="getImagePath($t(`s4.items[${index}].img`, 'en'))"
                        :srcset="
                            getImagePath($t(`s4.items[${index}].img2x`, 'en')) +
                                ' 2x'
                        "
                        :alt="item.title"
                    />
                </div>
                <div class="text-container">
                    <div class="title">{{ item.title }}</div>
                    <div class="content">
                        <p v-for="(t, i) in item.content" :key="'t-' + i">
                            {{ t }}
                        </p>
                    </div>
                </div>
            </swiper-slide>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
            <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
    </part>
</template>
<script>
import Part from '@/components/Part.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'S4',
    components: {
        Part,
    },
    data() {
        return {
            swiperOptions: {
                slidesPerView: 3,
                centeredSlides: true,
                loop: true,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                breakpoints: {
                    769: {
                        slidesPerView: 3,
                    },
                    320: {
                        slidesPerView: 1,
                    },
                },
            },
        };
    },
    computed: {
        ...mapGetters(['getImagePath']),
    },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/mixin';
.section-4 {
    height: 1347px;
    margin-bottom: -327px;
    z-index: 1;
    @include bg(
        $name: 'home/s4/bg.png',
        $name-retina: 'home/s4/bg@2x.png',
        $size: auto 100%
    );
    .swiper-container {
        position: absolute;
        width: 100%;
        left: 0;
        top: 450px;
        padding-bottom: 28px;
        .swiper-slide {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            .image-container {
                border: 2px solid rgba(255, 255, 255, 0.3);
                padding: 15px;
                width: 100%;
                transform: scale(0.75);
                max-width: 600px;
                box-sizing: border-box;
                transition: transform 0.3s;
                .image {
                    width: 100%;
                    opacity: 0.3;
                    transition: opacity 0.3s;
                }
            }
            .text-container {
                max-width: 520px;
                margin: 0 auto;
                visibility: hidden;
                display: flex;
                flex-direction: column;
                .title {
                    margin: 20px 0;
                    font-size: 36px;
                    line-height: 34px;
                    font-weight: bold;
                    flex-grow: 0;
                }
                .content {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    font-size: 18px;
                    line-height: 21px;
                    > p {
                        margin: 0;
                    }
                }
            }
            &.swiper-slide-active {
                .image-container {
                    transform: scale(1);
                    border-color: rgba(255, 255, 255, 0.4);
                    .image {
                        opacity: 1;
                    }
                }
                .text-container {
                    visibility: visible;
                }
            }
        }
        .swiper-pagination {
            bottom: 0;
            /deep/ .swiper-pagination-bullet {
                background-color: rgba(255, 255, 255, 0.3);
                margin: 0 5px;
                &.swiper-pagination-bullet-active {
                    background-color: white;
                }
            }
        }
        .swiper-button-prev,
        .swiper-button-next {
            width: 30px;
            height: 30px;
            margin-top: 9.06%;
            top: -7px;
            &:after {
                content: none;
            }
        }
        .swiper-button-prev {
            left: 33.33%;
            margin-left: -40px;
            @include bg(
                $name: 'home/s4/slider/btn-leftarrow.png',
                $name-retina: 'home/s4/slider/btn-leftarrow@2x.png',
                $size: auto 100%
            );
        }
        .swiper-button-next {
            right: 33.33%;
            margin-right: -40px;
            @include bg(
                $name: 'home/s4/slider/btn-rightarrow.png',
                $name-retina: 'home/s4/slider/btn-rightarrow@2x.png',
                $size: auto 100%
            );
        }
    }
}
@media (max-width: 768px) {
    .section-4 {
        margin-bottom: -370px;
        @include bg(
            $name: 'home/s4/bg-768.png',
            $name-retina: 'home/s4/bg-768@2x.png',
            $size: auto 100%
        );
        .swiper-container {
            .swiper-slide {
                padding: 0 84px;
                box-sizing: border-box;
                .text-container {
                    width: 100%;
                }
            }
            .swiper-button-prev,
            .swiper-button-next {
                margin-top: 27.2%;
                top: -44px;
            }
            .swiper-button-prev {
                left: 24px;
                margin-left: 0;
            }
            .swiper-button-next {
                right: 24px;
                margin-right: 0;
            }
        }
    }
}
@media (max-width: 480px) {
    .section-4 {
        height: 748px;
        margin-bottom: -132px;
        @include bg($name: 'home/s4/bg-414.png', $size: auto 100%);
        .swiper-container {
            top: 200px;
            .swiper-slide {
                padding: 0 60px;
                .image-container {
                    padding: 8px;
                }
                .text-container {
                    width: auto;
                    margin-left: -30px;
                    margin-right: -30px;
                    .title {
                        font-size: 24px;
                        line-height: 24px;
                    }
                    .content {
                        margin: 0 auto;
                        font-size: 12px;
                        line-height: 14px;
                    }
                }
            }
            .swiper-button-prev,
            .swiper-button-next {
                width: 24px;
                height: 24px;
                top: -40px;
            }
        }
    }
}
@media (max-width: 414px) {
    .section-4 {
        height: 646px;
    }
}
</style>
