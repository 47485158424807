<template>
    <part class="section-3">
        <div class="bg">
            <div class="clouds item"></div>
            <div class="volcano item"></div>
            <div class="girl item"></div>
        </div>
        <div class="title">
            <img
                :src="getImagePath($t('s3.title'))"
                :srcset="getImagePath($t('s3.title2x')) + ' 2x'"
            />
        </div>
    </part>
</template>
<script>
import Part from '@/components/Part.vue';
import { mapState, mapGetters } from 'vuex';

export default {
    name: 'S3',
    components: {
        Part,
    },
    computed: {
        ...mapState(['locale']),
        ...mapGetters(['getImagePath']),
    },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/mixin';
.section-3 {
    height: 1402px;
    margin-bottom: -309px;
    .bg {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        @include bg(
            $name: 'home/s3/bg/sky.jpg',
            $name-retina: 'home/s3/bg/sky@2x.jpg',
            $size: auto 100%
        );
        > .item {
            position: absolute;
            left: 0;
            &.clouds {
                width: 100%;
                height: 707px;
                top: 0;
                @include bg(
                    $name: 'home/s3/bg/clouds.png',
                    $name-retina: 'home/s3/bg/clouds@2x.png',
                    $size: auto 100%
                );
            }
            &.volcano {
                width: 100%;
                height: 1048px;
                top: 354px;
                @include bg(
                    $name: 'home/s3/bg/volcano.png',
                    $name-retina: 'home/s3/bg/volcano@2x.png',
                    $size: auto 100%
                );
            }
            &.girl {
                width: 1091px;
                height: 983px;
                margin-left: -960px;
                left: 50%;
                top: 386px;
                @include bg(
                    $name: 'home/s3/bg/girl.png',
                    $name-retina: 'home/s3/bg/girl@2x.png',
                    $size: auto 100%
                );
            }
        }
    }
    .title {
        position: absolute;
        width: 600px;
        left: 50%;
        top: 848px;
        margin-left: -105px;
        img {
            display: block;
            width: 100%;
        }
    }
}
@media (max-width: 768px) {
    .section-3 {
        .bg {
            > .item {
                &.girl {
                    top: 416px;
                    margin-left: -896px;
                }
            }
        }
        .title {
            width: 65.1%;
            top: 796px;
            margin-left: 0;
            left: auto;
            right: 24px;
        }
    }
}
@media (max-width: 480px) {
    .section-3 {
        height: 770px;
        margin-bottom: -340px;

        .bg {
            @include bg($name: 'home/s3/bg/sky-414.png', $size: cover);
            > .item {
                &.clouds {
                    display: none;
                }
                &.volcano {
                    width: 100%;
                    height: 489px;
                    top: 90px;
                    @include bg(
                        $name: 'home/s3/bg/volcano-414.png',
                        $size: cover
                    );
                }
                &.girl {
                    width: 313px;
                    height: 530px;
                    top: 80px;
                    left: 0;
                    margin-left: 0;
                    @include bg($name: 'home/s3/bg/girl-414.png', $size: cover);
                }
            }
        }
        .title {
            width: 65.1%;
            top: 292px;
            margin-left: 0;
            left: auto;
            right: 16px;
        }
    }
}
</style>
