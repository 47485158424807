export default {
    folder: 'en',
    language: 'English',
    support: {
        SEA: [
            { path: 'en', locale: 'en' },
            { path: 'th', locale: 'th' },
            { path: 'id', locale: 'id' },
            { path: 'zh', locale: 'zh' },
        ],
        NA: [
            { path: 'en', locale: 'en' },
            { path: 'es', locale: 'es' },
            { path: 'pt', locale: 'pt' },
        ],
        EU: [
            { path: 'en', locale: 'en' },
            { path: 'de', locale: 'de' },
            { path: 'es', locale: 'es_eu' },
            { path: 'fr', locale: 'fr' },
            { path: 'ru', locale: 'ru' },
            { path: 'tr', locale: 'tr' },
        ],
    },
    s1: {
        vid: '7db8838f982576a4416c0c62408260a8',
        slogan: '@:folder/home/s1/slogan.png',
        slogan2x: '@:folder/home/s1/slogan@2x.png',
    },
    s2: {
        title: 'A New Beginning',
        content:
            'Immerse yourself into the brand new adventure of RO 2.0! Enroll into the Cryptura Academy, explore the magical world of Eclage, and experience the powers of the stealthy Ninja.',
    },
    s3: {
        title: '@:folder/home/s3/title.png',
        title2x: '@:folder/home/s3/title@2x.png',
    },
    s4: {
        items: [
            {
                title: 'Power Level To 120!',
                img: '@:folder/home/s4/slider/p1.jpg',
                img2x: '@:folder/home/s4/slider/p1@2x.jpg',
                content: [
                    'First stop - Cryptura Academy.',
                    'Learn everything about the game in just 21 days and power level to 120 in just 30 minutes. Get exclusive Cryptura Academy items like costumes and pets too! ',
                ],
            },
            {
                title: 'Unravel The Darkest Secret',
                img: '@:folder/home/s4/slider/p2.jpg',
                img2x: '@:folder/home/s4/slider/p2@2x.jpg',
                content: [
                    'Enter at your own risk because the Guardians don’t take trespassing lightly. After all, the Palace is hiding a deep, dark secret...',
                ],
            },
            {
                title: 'Behold the Magic',
                img: '@:folder/home/s4/slider/p3.jpg',
                img2x: '@:folder/home/s4/slider/p3@2x.jpg',
                content: [
                    'Enter the mysterious Eclage, home of the pixies and many other magical creatures. What glorious loot will you find here? And what new horror lies await?',
                ],
            },
        ],
    },
    s5: {
        title: 'Enter The Ninja',
        description:
            'With a range of deadly ninjutsu skills, the Ninja is a remarkable character class experience you won’t want to miss! ',
        skills: [
            {
                name: 'Summoning - Ogare',
                type: 'Active Skill',
                icon: '@:folder/home/s5/skills/p1.png',
                icon2x: '@:folder/home/s5/skills/p1@2x.png',
                description:
                    'Summon one Thunder Beast around you, inflicting M.ATK *660% Wind Magic DMG on 1 enemy unit within 6 meters every second. The thunder beast lasts for 9 seconds',
                vid: 'f44d104449b5a3a2a55a4056fe1bcfc8',
            },
            {
                name: 'Summoning - Uzumaki Maru',
                type: 'Active Skill',
                icon: '@:folder/home/s5/skills/p2.png',
                icon2x: '@:folder/home/s5/skills/p2@2x.png',
                description:
                    'Summon a ninja frog to repeatedly pound on all enemy units within range, inflicting of M.ATK *1340% Water Magic DMG with a 100% chance of freezing the targets. Ninja frogs deals extra 50% DMG to enemy units within Marsh.',
                vid: 'f9318f9b0c864178d6d8fef12580fb1b',
            },
            {
                name: 'Ninjutsu - Thousand Shadows',
                type: 'Active Skill',
                icon: '@:folder/home/s5/skills/p3.png',
                icon2x: '@:folder/home/s5/skills/p3@2x.png',
                description:
                    "Summon one Shadow Clone to assist in battle. The Shadow Clone can perform some skills. The higher level the skills, the better the Shadow Clone's Attributes. Up to 2 Shadow Clones may exist simultaneously and each lasts for 20 seconds.",
                vid: 'cf12be90275544f723ee9610fee405f5',
            },
            {
                name: 'Summoning - Serpent',
                type: 'Active Skill',
                icon: '@:folder/home/s5/skills/p4.png',
                icon2x: '@:folder/home/s5/skills/p4@2x.png',
                description:
                    'Summon one Yamata Serpent under your feet to wrap around the enemy, inflicting M.ATK *1160% Earth Magic DMG on all enemy units within 5 meters per second for 3 seconds with a 10% chance of causing them to fear for 5 seconds.',
                vid: '8feba9d06ae6943e3a9d7c0494be4daa',
            },
            {
                name: 'Summoning - Scorching Manzomaru',
                type: 'Active Skill',
                icon: '@:folder/home/s5/skills/p5.png',
                icon2x: '@:folder/home/s5/skills/p5@2x.png',
                description:
                    'Summon Fire Dragon to attack the designated area. After a pause, cause M.ATK *2880% Fire Magic DMG to enemies within 5 meters.',
                vid: 'd0e653c8839f710f998b7c5eb6cc2016',
            },
            {
                name: 'Amaterasu Flash',
                type: 'Active Skill',
                icon: '@:folder/home/s5/skills/p6.png',
                icon2x: '@:folder/home/s5/skills/p6@2x.png',
                description:
                    'A Ninja dashes 5 meters forward, inflicting ATK *1800% Physical DMG on enemy units in the way with a 28% chance of causing the targets to bleed for 10 seconds. A Sword must be equipped.',
                vid: '13650f831a09c26de24abc8e509aefde',
            },
        ],
    },
    s6: {
        title: 'Improved UI',
        description:
            'Game interface has been revamped to make your experience better than before!',
    },
    s7: {
        items: [
            {
                title: 'Max Level Increase to 170!',
                img: '@:folder/home/s7/p1.jpg',
                img2x: '@:folder/home/s7/p1@2x.jpg',
                content:
                    'Up the stakes and grow stronger with a new max level cap.',
            },
            {
                title: 'New Feature: Offline Grinding',
                img: '@:folder/home/s7/p2.jpg',
                img2x: '@:folder/home/s7/p2@2x.jpg',
                content:
                    'Now you can even farm for loot and EXP when you go AFK!',
            },
        ],
    },
    s8: {
        content:
            'Legend has it that the Gods promised all races in Midgard that whoever reached the peak of the Divine Tree would be blessed. Many tried to scale the Divine Tree but only a group of tenacious pixies made it to the top. They received the Gods’ blessings of longevity, beauty and innate magical gifts, and thereafter built their home here, and called it Eclage.',
    },
    downloadGroup: {
        now: {
            img: '@:folder/home/download-group/btn-download.png',
            img2x: '@:folder/home/download-group/btn-download@2x.png',
            link: {
                SEA: 'https://l.tapdb.net/bzrhN7kM',
                NA: 'https://l.tapdb.net/4zgHlT5z',
                EU: '',
            },
        },
        pc: {
            img: '@:folder/home/download-group/btn-pc.png',
            link: {
                SEA:
                    'https://www.ldplayer.net/games/ragnarok-m-eternal-love-on-pc.html',
                NA:
                    'https://www.ldplayer.net/games/ragnarok-m-eternal-loverom-on-pc.html',
                EU:
                    'https://www.ldplayer.net/games/ragnarok-m-eternal-love-eu-on-pc.html',
            },
        },
        apple: {
            link: {
                SEA: 'https://apps.apple.com/app/id1404051022',
                NA: 'https://apps.apple.com/app/id1444739251',
                EU:
                    'https://apps.apple.com/ae/app/ragnarok-m-eternal-love-eu/id1468396562',
            },
        },
        google: {
            link: {
                SEA:
                    'https://play.google.com/store/apps/details?id=com.gravity.romg',
                NA:
                    'https://play.google.com/store/apps/details?id=com.gravity.romNAg',
                EU:
                    'https://play.google.com/store/apps/details?id=com.gravity.romEUg',
            },
        },
        taptap: {
            link: {
                SEA: 'https://www.tap.io/app/191499',
                NA: 'https://www.tap.io/app/155376',
                EU: 'https://www.taptap.io/app/175346',
            },
        },
    },
    footer: {
        fb: {
            icon: '@:folder/home/footer/icon-fb.png',
            icon2x: '@:folder/home/footer/icon-fb@2x.png',
            link: {
                SEA: 'https://www.facebook.com/PlayRagnarokM.EN',
                NA: 'https://www.facebook.com/PlayRagnarokMGlobal/',
                EU: 'https://www.facebook.com/PlayRagnarokMEU',
            },
        },
        ig: {
            icon: '@:folder/home/footer/icon-ig.png',
            icon2x: '@:folder/home/footer/icon-ig@2x.png',
            link: {
                SEA: 'https://www.instagram.com/RagnarokMOfficial/',
                NA: 'https://www.instagram.com/RagnarokMOfficial/',
                EU: 'https://www.instagram.com/RagnarokMOfficial/',
            },
        },
        yt: {
            icon: '@:folder/home/footer/icon-yt.png',
            icon2x: '@:folder/home/footer/icon-yt@2x.png',
            link: {
                SEA: 'https://www.youtube.com/c/RagnarokMEternalLoveOfficial',
                NA: 'https://www.youtube.com/c/RagnarokMEternalLoveOfficial',
                EU: 'https://www.youtube.com/c/RagnarokMEternalLoveOfficial',
            },
        },
        policies: [
            {
                SEA: ['service', 'privacy'],
                NA: ['service', 'privacy', 'notice'],
                EU: ['service', 'privacy', 'cookies'],
            },
            {
                SEA: [],
                NA: [],
                EU: ['terms', 'policy'],
            },
        ],
        service: {
            text: 'Terms of Service',
            link: {
                SEA: 'https://sea.ragnaroketernallove.com/terms-of-service',
                NA: 'https://na.ragnaroketernallove.com/terms-of-service',
                EU:
                    'https://eu.ragnaroketernallove.com/introduction/service.html',
            },
        },
        privacy: {
            text: 'Privacy Policy',
            link: {
                SEA: 'https://sea.ragnaroketernallove.com/privacy-policy',
                NA: 'https://na.ragnaroketernallove.com/terms-of-service',
                EU:
                    'https://eu.ragnaroketernallove.com/introduction/privacy.html',
            },
        },
        notice: {
            text: 'Privacy Notice for California Residents',
            link: {
                SEA: '',
                NA: 'https://na.ragnaroketernallove.com/privacy-notice',
                EU: '',
            },
        },
        cookies: {
            text: 'Cookies Policy',
            link: {
                SEA: '',
                NA: '',
                EU:
                    'https://eu.ragnaroketernallove.com/introduction/cookies.html',
            },
        },
        terms: {
            text: 'Terms of Service (For EU Residents)',
            link: {
                SEA: '',
                NA: '',
                EU:
                    'https://eu.ragnaroketernallove.com/introduction/terms.html',
            },
        },
        policy: {
            text: 'Privacy Policy  (For EEA and UK Residents)',
            link: {
                SEA: '',
                NA: '',
                EU:
                    'https://eu.ragnaroketernallove.com/introduction/policy.html',
            },
        },
    },
};
