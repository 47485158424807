export default {
    folder: 'de',
    language: 'Deutsch',
    s1: {
        vid: 'dfd11d254563e4713065442d7aeae01b',
        slogan: '@:folder/home/s1/slogan.png',
        slogan2x: '@:folder/home/s1/slogan@2x.png',
    },
    s2: {
        title: 'Ein neuer Anfang',
        content:
            'Begebe dich in das neue Abenteuer von RO 2.0! Schreibe dich in die Kriatura-Akademie ein, erkunde die magische Welt von Eclage und erlebe die Kräfte des heimlichen Ninjas.',
    },
    s3: {
        title: '@:folder/home/s3/title.png',
        title2x: '@:folder/home/s3/title@2x.png',
    },
    s4: {
        items: [
            {
                title: 'Kraftlevel bis auf 120!',
                content: [
                    'Erster Halt - Kriatura-Akademie',
                    'Lerne alles über das Spiel innerhalb 21 Tagen und erhöhe das Kraftlevel auf 120 innerhalb von 30 Minuten. Erhalte exklusive Gegenstände der Kriatura-Akademie, wie zum Beispiel Mode oder Haustiere!',
                ],
            },
            {
                title: 'Löse die dunklen Geheimnisse auf',
                content: [
                    'Betreten auf eigene Gefahr, denn die Wächter werden das Betreten nicht auf die leichte Schulter nehmen. Schließlich versteckt der Palast ein tiefes dunkles Geheimnis...',
                ],
            },
            {
                title: 'Genieße die Magie',
                content: [
                    'Betrete das geheime Eclage, Zuhause der Elfen und vielen anderen magischen Wesen. Was für eine ehrenhafte Beute wirst du hier finden? Und was für neue schreckliche Lügen warten auf dich?',
                ],
            },
        ],
    },
    s5: {
        title: 'Werde zum Ninja',
        description:
            'Der Ninja ist ein spezieller Charakter mit vielen tödlichen Ninjutsu-Fähigkeiten, man sollte dieses Erlebnis nicht verpassen! ',
        skills: [
            {
                name: 'Beschwörung - Ogare',
                type: 'Aktive Fähigkeit',
                description:
                    'Beschwört ein Donnerbiest in deiner Nähe, verursacht jede Sekunde M.ATK *660% magischen Windschaden an 1 feindliche Einheit innerhalb 6 Meter. Das Donnerbiest bleibt 9 Sekunden vorhanden',
            },
            {
                name: 'Beschwörung - Uzumaki Maruare',
                type: 'Aktive Fähigkeit',
                description:
                    'Beschwört einen Ninja-Frosch der wiederholt alle feindlichen Einheiten in Reichweite schlägt, verursacht M.ATK *1340% magischen Wasserschaden mit einer 100% Chance, die Ziele einzufrieren. Der Ninja-Frosch verursacht 50% zusätzlichen Schaden an Feinde, die beim Marschieren sind',
            },
            {
                name: 'Ninjutsu - Tausend Schatten',
                type: 'Aktive Fähigkeit',
                description:
                    'Beschwört einen Schattenklon für die Unterstützung im Kampf. Der Schattenklon kann ein paar Fähigkeiten ausführen. Je höher das Level der Fähigkeiten, desto besser die Attribute der Schattenklone. Bis zu 2 Schattenklone können zusammen existieren, jeder bis zu maximal 20 Sekunden.',
            },
            {
                name: 'Beschwörung - Schlange',
                type: 'Aktive Fähigkeit',
                description:
                    'Beschwört einen Diener von Yamata vor sich, um den Feind zu umzingeln, verursacht M.ATK *1160% magischen Erdschaden an alle feindlichen Einheiten innerhalb 5 Meter pro Sekunde für 3 Sekunden, mit einer 10% Chance, ihnen Furcht für 5 Sekunden hinzuzufügen.',
            },
            {
                name: 'Beschwörung - Berennender Manzomaru',
                type: 'Aktive Fähigkeit',
                description:
                    'Beschwört einen Feuerdrachen, um den bestimmten Bereich anzugreifen. Verursacht nach einer Pause M.ATK *2880% magischen Feuerschaden gegen Feinde innerhalb 5 Metern.',
            },
            {
                name: 'Amaterasu-Blitz',
                type: 'Aktive Fähigkeit',
                description:
                    'Der Ninja rast 5 Meter nach vorne, verursacht ATK *1800% physischen Schaden gegen feindliche Einheiten auf dem Weg mit einer 28% Chance, sie für 10 Sekunden bluten zu lassen. Ein Schwert muss getragen werden.',
            },
        ],
    },
    s6: {
        title: 'Verbesserter UI',
        description:
            'Spieloberfläche wurde optimiert, um dir damit eine bessere Erfahrung zu geben!',
    },
    s7: {
        items: [
            {
                title: 'Maximales Level wurde auf 170 erhöht',
                content:
                    'Mit dem neuen maximalen Level hat man höheren Einsatz und Wachstum',
            },
            {
                title: 'Neues Funktion: Offline-Kampf',
                content:
                    'Jetzt kannst du auch im AFK die Beute und Exp erhalten!',
            },
        ],
    },
    s8: {
        content:
            'Die Legende besagt, dass die Götter alle Rassen in Midgard versprochen haben, dass alle Personen gesegnet werden, die die Spitze des göttlichen Baums erreicht haben. Vielen haben versucht den göttlichen Baum zu besteigen, es hat aber nur eine Gruppe von Elfen es an die Spitze geschafft. Sie haben den göttlichen Segen der Langlebigkeit, Schönheit und auch magische Geschenke bekommen. So haben sie dann hier ihr Zuhause gebaut und nannten es Eclage.',
    },
    downloadGroup: {
        now: {
            img: '@:folder/home/download-group/btn-download.png',
            img2x: '@:folder/home/download-group/btn-download@2x.png',
            link: {
                SEA: '',
                NA: '',
                EU: '',
            },
        },
        pc: {
            img: '@:folder/home/download-group/btn-pc.png',
            link: {
                SEA:
                    'https://www.ldplayer.net/games/ragnarok-m-eternal-love-eu-on-pc.html',
                NA:
                    'https://www.ldplayer.net/games/ragnarok-m-eternal-love-eu-on-pc.html',
                EU:
                    'https://www.ldplayer.net/games/ragnarok-m-eternal-love-eu-on-pc.html',
            },
        },
        apple: {
            link: {
                SEA:
                    'https://apps.apple.com/ae/app/ragnarok-m-eternal-love-eu/id1468396562',
                NA:
                    'https://apps.apple.com/ae/app/ragnarok-m-eternal-love-eu/id1468396562',
                EU:
                    'https://apps.apple.com/ae/app/ragnarok-m-eternal-love-eu/id1468396562',
            },
        },
        google: {
            link: {
                SEA:
                    'https://play.google.com/store/apps/details?id=com.gravity.romEUg',
                NA:
                    'https://play.google.com/store/apps/details?id=com.gravity.romEUg',
                EU:
                    'https://play.google.com/store/apps/details?id=com.gravity.romEUg',
            },
        },
        taptap: {
            link: {
                SEA: 'https://www.taptap.io/app/175346',
                NA: 'https://www.taptap.io/app/175346',
                EU: 'https://www.taptap.io/app/175346',
            },
        },
    },
    footer: {
        fb: {
            link: {
                SEA: 'https://www.facebook.com/PlayRagnarokMEU.de/',
                NA: 'https://www.facebook.com/PlayRagnarokMEU.de/',
                EU: 'https://www.facebook.com/PlayRagnarokMEU.de/',
            },
        },
        ig: {
            link: {
                SEA: 'https://www.instagram.com/RagnarokMOfficial/',
                NA: 'https://www.instagram.com/RagnarokMOfficial/',
                EU: 'https://www.instagram.com/RagnarokMOfficial/',
            },
        },
        yt: {
            link: {
                SEA: 'https://www.youtube.com/c/RagnarokMEternalLoveOfficial',
                NA: 'https://www.youtube.com/c/RagnarokMEternalLoveOfficial',
                EU: 'https://www.youtube.com/c/RagnarokMEternalLoveOfficial',
            },
        },
        service: {
            text: 'Nutzungsbedingungen',
        },
        privacy: {
            text: 'Datenschutzbestimmungen',
        },
        notice: {
            text: 'Datenschutzerklärung für Einwohner in Kalifornien',
        },
        cookies: {
            text: 'Richtlinien der Cookies',
        },
        terms: {
            text: 'Nutzungsbedingungen (Bewohner der EU)',
        },
        policy: {
            text:
                'Datenschutzrichtlinien (Bewohner der EU und des Vereinigten Königreichs)',
        },
    },
};
