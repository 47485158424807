<template>
    <part class="section-2">
        <div class="title">{{ $t('s2.title') }}</div>
        <div class="content">{{ $t('s2.content') }}</div>
    </part>
</template>
<script>
import Part from '@/components/Part.vue';

export default {
    name: 'S2',
    components: {
        Part,
    },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/mixin';
.section-2 {
    text-align: center;
    z-index: 1;
    height: 1082px;
    margin-bottom: -526px;
    padding-top: 328px;
    box-sizing: border-box;
    @include bg(
        $name: 'home/s2/bg.png',
        $name-retina: 'home/s2/bg@2x.png',
        $size: auto 100%
    );
    .title {
        position: relative;
        font-size: 48px;
        line-height: 56px;
        margin-bottom: 24px;
        font-weight: bold;
    }
    .content {
        position: relative;
        font-size: 24px;
        line-height: 28px;
        max-width: 788px;
        margin: 0 auto;
    }
}
@media (max-width: 768px) {
    .section-2 {
        height: 724px;
        margin-bottom: -440px;
        padding-top: 202px;
        @include bg(
            $name: 'home/s2/bg-768.png',
            $name-retina: 'home/s2/bg-768@2x.png',
            $size: auto 100%
        );
        .content {
            max-width: 528px;
            font-size: 18px;
            line-height: 21px;
        }
    }
}
@media (max-width: 480px) {
    .section-2 {
        height: 393px;
        margin-top: -180px;
        margin-bottom: -125px;
        padding-top: 110px;
        @include bg($name: 'home/s2/bg-414.png', $size: auto 100%);
        .title {
            font-size: 24px;
            line-height: 1;
            margin-bottom: 20px;
        }
        .content {
            max-width: 100%;
            font-size: 12px;
            line-height: 14px;
            padding: 0 30px;
        }
    }
}
@media (max-width: 414px) {
    .section-2 {
        height: 339px;
        padding-top: 70px;
    }
}
</style>
