<template>
    <div class="footer">
        <div class="brand-xd">
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 52 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M48.9178 2.29358C47.632 1.30276 46.0626 0.623861 44.3418 0.330283C39.0284 -0.605497 35.7949 0.733952 33.1098 2.31193C30.6895 3.74313 29.1578 5.77982 28.2691 7.30276V7.32111C28.2124 7.4312 28.1746 7.54129 28.1746 7.66973C28.1935 8.05505 28.5149 8.36698 28.912 8.34863C28.9309 8.34863 28.9498 8.34863 28.9498 8.34863C33.9607 7.81652 38.8582 9.17431 42.8669 12.2752C42.9804 12.367 43.0938 12.4404 43.2073 12.5321C43.264 12.5872 43.3397 12.6239 43.3964 12.6789C45.2873 14.1284 47.1971 14.6239 48.7855 14.0734C50.2982 13.5413 51.3949 12.0917 51.8109 10.1284C52.4538 7.04588 51.3949 4.18349 48.9178 2.29358Z"
                    fill="#FF6600"
                ></path>
                <path
                    d="M31.1433 10.844C26.1324 10.9541 20.7433 9.19266 16.6968 4.51376C16.3375 4.1101 16.035 3.72477 15.7703 3.3578C15.5244 3.08257 15.1652 2.91743 14.7492 2.91743C14.1819 2.91743 13.7092 3.24771 13.5012 3.72477C13.3499 4.14679 13.1797 4.60551 13.0095 5.13762C12.1775 7.81652 11.6859 12.0184 13.2364 16.1284C14.8059 20.2385 17.1695 23.6514 22.5586 26.422C28.4204 29.4495 35.7193 28.2752 39.0284 21.9817C41.8837 16.4954 38.9339 10.6789 31.1433 10.844Z"
                    fill="#FF6600"
                ></path>
                <path
                    d="M11.0996 18.1835C11.0429 18.0551 10.9861 17.8899 10.9294 17.7431C9.15195 13.156 9.0385 8.34863 10.5512 3.79817C10.5512 3.79817 10.589 3.61469 10.589 3.52295C10.5701 3.10093 10.192 2.789 9.75704 2.80735C9.68141 2.80735 9.60577 2.8257 9.53014 2.86239C8.2065 3.33946 6.80723 4.05506 5.52141 5.13762C3.80068 6.56882 2.06104 8.31194 0.983226 11.2844C0.567226 12.3853 0.28359 13.633 0.0944988 14.9908C-0.13241 16.6239 0.0566806 18.2385 0.605044 19.6697C1.64504 22.3486 3.95195 24.0734 6.93959 24.422C8.73595 24.6239 10.2298 24.1652 11.0429 23.156C11.232 22.9174 11.3832 22.6422 11.4967 22.3303C11.8749 21.3028 11.7614 19.945 11.1752 18.422C11.1563 18.3303 11.1185 18.2569 11.0996 18.1835Z"
                    fill="#FF6600"
                ></path>
            </svg>
            <span>© 2021 LTD. All Rights Reserved</span>
        </div>
        <div class="other">
            <div class="social-media">
                <a
                    class="icon"
                    v-for="type in socialMedia"
                    :key="type"
                    :class="'icon-' + type"
                    :href="$t(`footer.${type}.link.${area}`)"
                    target="_blank"
                    ><img
                        :src="getImagePath($t(`footer.${type}.icon`, 'en'))"
                        :srcset="
                            getImagePath($t(`footer.${type}.icon2x`, 'en')) +
                                ' 2x'
                        "
                /></a>
            </div>
            <div class="policies">
                <div
                    class="line"
                    v-for="(arr, index) in policies"
                    :key="'policies-' + index"
                >
                    <template v-for="(item, index) in arr">
                        <div
                            class="divide"
                            v-if="index > 0"
                            :key="'divide-' + index"
                        ></div>
                        <a :href="item.link" :key="index" target="_blank">{{ item.text }}</a>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';

export default {
    name: 'Footer',
    data() {
        return {
            socialMedia: ['fb', 'ig', 'yt'],
        };
    },
    computed: {
        ...mapState(['area']),
        ...mapGetters(['getImagePath']),
        policies: function() {
            return [this.getPolicies(0), this.getPolicies(1)].filter(v => v.length);
        },
    },
    methods: {
        getPolicies: function(index) {
            let arr = this.$t(`footer.policies[${index}].${this.area}`, 'en');
            let result = [];
            if (arr && arr.length) {
                result = arr.map((name) => ({
                    text: this.$t(`footer.${name}.text`),
                    link: this.$t(`footer.${name}.link.${this.area}`),
                }));
            }
            return result;
        },
    },
};
</script>
<style lang="scss" scoped>
.footer {
    display: flex;
    flex-direction: row;
    background-color: #000;
    padding: 0 30px;
    color: #fff;
    font-size: 13px;
    line-height: 16px;
    justify-content: space-between;
    align-items: center;
    min-height: 80px;
    a {
        color: #fff;
        text-decoration: none;
    }
    > .brand-xd {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;

        svg {
            margin-right: 15px;
            height: 24px;
            width: auto;
        }
    }
    > .other {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        > .social-media {
            display: flex;
            margin-right: 60px;
            align-items: center;
            .icon {
                width: 30px;
                height: 30px;
                margin: 0 5px;
                > img {
                    width: 100%;
                }
            }
        }
        > .policies {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            .divide {
                width: 1px;
                height: 10px;
                background-color: #fff;
                margin: 0 20px;
            }
            > .line {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                padding: 5px 0;
                > a{
                    line-height: 15px;
                }
            }
        }
    }
}
@media (max-width: 1200px) {
    .footer {
        padding: 35px 0;
        flex-direction: column-reverse;
        > .other {
            flex-direction: column;
            align-items: center;
            > .social-media {
                margin-right: 0;
                > .icon {
                    width: 40px;
                    height: 40px;
                }
            }
            > .policies {
                margin: 15px 0;
            }
        }
    }
}
@media (max-width: 6400px) {
    .footer {
        > .other {
            > .policies {
                font-size: 12px;
                .divide {
                    margin: 0 10px;
                }
                > .line {
                    flex-wrap: wrap;
                    justify-content: center;
                }
            }
        }
    }
}
</style>
