export default {
    folder: 'id',
    language: 'Bahasa Indonesia',
    s1: {
        vid: '29725e8184e7e3ff8f873eff6af698d7',
        slogan: '@:folder/home/s1/slogan.png',
        slogan2x: '@:folder/home/s1/slogan@2x.png',
    },
    s2: {
        title: 'Sebuah Awal Baru',
        content:
            'Libatkan dirimu dalam petualangan baru RO 2.0! Mendaftarlah ke Cryptura Academy, jelajahi dunia magis Eclage, dan rasakan kekuatan Ninja yang tersembunyi.',
    },
    s3: {
        title: '@:folder/home/s3/title.png',
        title2x: '@:folder/home/s3/title@2x.png',
    },
    s4: {
        items: [
            {
                title: 'Tingkatkan Level Hingga 120!',
                content: [
                    'Perhentian pertama - Cryptura Academy.',
                    'Pelajari segala sesuatu tentang game hanya dalam 21 hari dan tingkatkan level hingga 120 hanya dalam 30 menit. Dapatkan juga item Cryptura Academy eksklusif seperti kostum dan hewan peliharaan!',
                ],
            },
            {
                title: 'Ungkap Rahasia Paling Gelap',
                content: [
                    'Tanggung sendiri risikonya kalau kamu masuk karena Guardian tidak menganggap enteng orang yang masuk tanpa izin. Bagaimanapun, Palace menyembunyikan rahasia yang sangat gelap...',
                ],
            },
            {
                title: 'Lihatlah Keajaibannya',
                content: [
                    'Masuki Eclage yang misterius, rumah para peri dan banyak makhluk ajaib lainnya. Loot berharga apa yang akan kamu temukan di sini? Dan horror baru apakah yang menantimu?',
                ],
            },
        ],
    },
    s5: {
        title: 'Masuki Dunia Ninja',
        description:
            'Dengan berbagai skill ninjutsu yang mematikan, kamu tidak akan mau melewatkan pengalaman bermain kelas Ninja yang luar biasa! ',
        skills: [
            {
                name: 'Summoning - Ogare',
                type: 'Skill Aktif',
                description:
                    'Summon satu Thunder Beast untuk mengelilingi diri sendiri, setiap detik menyebabkan Wind Magic Damage sebesar M.ATK*660% pada 1 musuh dalam jarak 6 meter, durasi Thunder Beast berlangsung selama 9 detik',
            },
            {
                name: 'Summoning - Uzumaki Maru',
                type: 'Skill Aktif',
                description:
                    'Summon satu Ninja Frog untuk terus melakukan pukulan berat pada musuh dalam jangkauan, menyebabkan Water Magic Damage sebesar M.ATK*1340% pada musuh alam jangkauan, dan ada peluang 100% membuat target terkena [Freeze]. Ninja Frog menyebabkan tambahan 50% damage pada musuh yang berada dalam [Marsh Skill]',
            },
            {
                name: 'Ninjutsu - Thousand Shadows',
                type: 'Skill Aktif',
                description:
                    'Summon satu Shadow Clone untuk membantu dalam pertempuran, Shadow Clone akan menggunakan beberapa skill, semakin tinggi level skill, semakin tinggi atribut Shadow Clone. Paling banyak 2 Shadow Bunshin, durasi setiap Shadow Clone adalah 30 detik',
            },
            {
                name: 'Summoning - Serpent',
                type: 'Skill Aktif',
                description:
                    'Summon seekor Yamata di kakinya, belit musuh, setiap detik pada musuh dalam jangkauan 5 meter menyebabkan Earth Magic Damage sebesar M.ATK*1160%, durasi 3 detik, dan ada peluang 10% memberikan [Fear] selama 5 detik',
            },
            {
                name: 'Summoning - Scorching Manzomaru',
                type: 'Skill Aktif',
                description:
                    'Summon naga api untuk menyerang area yang ditentukan, menyebabkan *2880% Fire Magic DMG kepada musuh dalam area 5 meter',
            },
            {
                name: 'Amaterasu Flash',
                type: 'Skill Aktif',
                description:
                    'Ninja berlari ke depan sejauh 5 meter, menyebabkan Physical DMG sebesar ATK *1800% pada musuh di sepanjang jalan, dan memiliki peluang 28% untuk menyebabkan musuh jatuh ke status [Bleed], durasi 10 detik. Harus memakai weapon tipe Pedang Panjang.',
            },
        ],
    },
    s6: {
        title: 'Peningkatan UI',
        description:
            'Interface game sudah diubah untuk membuat pengalamanmu lebih baik dari sebelumnya!',
    },
    s7: {
        items: [
            {
                title: 'Level Maksimum Meningkat menjadi 170',
                content:
                    'Tingkatkan lagi taruhannya dan tumbuhlah lebih kuat dengan batas level maksimum yang baru.',
            },
            {
                title: 'Fitur Baru: Offline Grinding',
                content:
                    'Sekarang kamu tetap bisa farming loot dan EXP waktu kamu AFK!',
            },
        ],
    },
    s8: {
        content:
            'Legenda mengatakan Dewa menjanjikan kepada semua ras bahwa siapapun yang dapat mencapai puncak Divine Tree akan diberkati. Banyak yang mencoba menapaki Divine Tree tapi hanya sekelompok peri pantang menyerah yang mencapai puncak. Mereka menerima berkat Dewa berupa umur panjang, kecantikan, dan bakat sihir alamiah, lalu membuat rumah mereka di sana dan menyebutnya Eclage.',
    },
    downloadGroup: {
        now: {
            img: '@:folder/home/download-group/btn-download.png',
            img2x: '@:folder/home/download-group/btn-download@2x.png',
            link: {
                SEA: 'https://l.tapdb.net/bzrhN7kM',
                NA: 'https://l.tapdb.net/bzrhN7kM',
                EU: 'https://l.tapdb.net/bzrhN7kM',
            },
        },
        pc: {
            img: '@:folder/home/download-group/btn-pc.png',
            link: {
                SEA:
                    'https://id.ldplayer.net/games/ragnarok-m-eternal-love-on-pc.html',
                NA:
                    'https://id.ldplayer.net/games/ragnarok-m-eternal-love-on-pc.html',
                EU:
                    'https://id.ldplayer.net/games/ragnarok-m-eternal-love-on-pc.html',
            },
        },
        apple: {
            link: {
                SEA: 'https://apps.apple.com/app/id1404051022',
                NA: 'https://apps.apple.com/app/id1404051022',
                EU: 'https://apps.apple.com/app/id1404051022',
            },
        },
        google: {
            link: {
                SEA:
                    'https://play.google.com/store/apps/details?id=com.gravity.romg',
                NA:
                    'https://play.google.com/store/apps/details?id=com.gravity.romg',
                EU:
                    'https://play.google.com/store/apps/details?id=com.gravity.romg',
            },
        },
        taptap: {
            link: {
                SEA: 'https://www.tap.io/app/191499',
                NA: 'https://www.tap.io/app/191499',
                EU: 'https://www.tap.io/app/191499',
            },
        },
    },
    footer: {
        fb: {
            link: {
                SEA: 'https://www.facebook.com/PlayRagnarokM.ID',
                NA: 'https://www.facebook.com/PlayRagnarokM.ID',
                EU: 'https://www.facebook.com/PlayRagnarokM.ID',
            },
        },
        ig: {
            link: {
                SEA: 'https://www.instagram.com/RagnarokMOfficial/',
                NA: 'https://www.instagram.com/RagnarokMOfficial/',
                EU: 'https://www.instagram.com/RagnarokMOfficial/',
            },
        },
        yt: {
            link: {
                SEA: 'https://www.youtube.com/c/RagnarokMEternalLoveOfficial',
                NA: 'https://www.youtube.com/c/RagnarokMEternalLoveOfficial',
                EU: 'https://www.youtube.com/c/RagnarokMEternalLoveOfficial',
            },
        },
        service: {
            text: 'Ketentuan Layanan',
        },
        privacy: {
            text: 'Kebijakan Privasi',
        },
        notice: {
            text: 'Privacy Notice for California Residents',
        },
        cookies: {
            text: 'Cookies Policy',
        },
        terms: {
            text: 'Terms of Service (For EU Residents)',
        },
        policy: {
            text: 'Privacy Policy  (For EEA and UK Residents)',
        },
    },
};
