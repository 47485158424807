<template>
    <div class="download-group">
        <a class="download-item download-now" :href="linkNow" v-if="linkNow">
            <img
                class="img"
                :src="getImagePath($t('downloadGroup.now.img'))"
                :srcset="getImagePath($t('downloadGroup.now.img2x')) + ' 2x'"
            />
        </a>
        <div class="download-other">
            <a class="btn-app btn-apple" :href="linkApple" v-if="linkApple">
            </a>
            <a class="btn-app btn-google" :href="linkGoogle" v-if="linkGoogle">
            </a>
            <a class="btn-app btn-taptap" :href="linkTaptap" v-if="linkTaptap">
            </a>
        </div>
        <a
            class="download-item download-pc"
            :href="linkPc"
            v-if="!isMobile && linkPc"
        >
            <img class="img" :src="getImagePath($t('downloadGroup.pc.img'))" />
        </a>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
    name: 'DownloadGroup',
    computed: {
        ...mapState(['area']),
        ...mapGetters(['getImagePath']),
        isMobile() {
            let flag = navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            );
            return flag;
        },
        linkNow() {
            return this.getLink('now');
        },
        linkPc() {
            return this.getLink('pc');
        },
        linkApple() {
            return this.getLink('apple');
        },
        linkGoogle() {
            return this.getLink('google');
        },
        linkTaptap() {
            return this.getLink('taptap');
        },
    },
    methods: {
        getLink(type) {
            let link = this.$t(`downloadGroup.${type}.link.${this.area}`);
            return link;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/mixin';
$types: apple, google, taptap;
.download-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    a {
        color: transparent;
    }
    .download-item {
        display: flex;
        align-items: center;
        > .img {
            width: 100%;
        }
    }
    .download-now {
        width: 368px;
    }
    .download-other {
        width: 100%;
        display: flex;
        justify-content: center;
        > .btn-app {
            width: 200px;
            height: 65px;
            margin: 0 10px;
            transition: width 0.3s, height 0.3s;
            @each $t in $types {
                &.btn-#{$t} {
                    @include bg(
                        $name: 'home/download-group/btn-#{$t}.png',
                        $name-retina: 'home/download-group/btn-#{$t}@2x.png',
                        $size: 100%
                    );
                }
            }
        }
    }
    .download-pc {
        width: 200px;
    }
    .download-now,
    .download-other,
    .download-pc {
        margin-top: 65px;
    }
}
@media (max-width: 768px) {
    .download-group {
        .download-other {
            > .btn-app {
                width: 160px;
                height: 52px;
            }
        }
    }
}
@media (max-width: 480px) {
    .download-group {
        .download-now {
            width: 200px;
        }
        .download-other {
            > .btn-app {
                width: 40px;
                height: 40px;
                @each $t in $types {
                    &.btn-#{$t} {
                        @include bg(
                            $name: 'home/download-group/btn-#{$t}-414.png',
                            $size: 100%
                        );
                    }
                }
            }
        }
        .download-now,
        .download-other,
        .download-pc {
            margin-top: 25px;
        }
    }
}
</style>
