<template>
    <part class="section-5">
        <div class="ninja"></div>
        <div class="skills-container">
            <div class="title">{{ $t('s5.title') }}</div>
            <div class="description">{{ $t('s5.description') }}</div>
            <div class="icons">
                <div
                    class="icon"
                    v-for="(skill, index) in $t('s5.skills')"
                    :class="{ active: skillActiveIndex === index }"
                    :key="index"
                    @click="skillActiveIndex = index"
                >
                    <img
                        class="icon"
                        :src="
                            getImagePath($t(`s5.skills[${index}].icon`, 'en'))
                        "
                        :srcset="
                            getImagePath(
                                $t(`s5.skills[${index}].icon2x`, 'en')
                            ) + ' 2x'
                        "
                        :alt="skill.title"
                    />
                </div>
            </div>
            <div class="skill-active">
                <xd-video
                    :vid="getInfo('vid')"
                    ref="xdvideo"
                    :options="videoOptions"
                ></xd-video>
                <div class="name">{{ getInfo('name') }}</div>
                <div class="type">{{ getInfo('type') }}</div>
                <div class="description">{{ getInfo('description') }}</div>
            </div>
        </div>
    </part>
</template>
<script>
import Part from '@/components/Part.vue';
import XdVideo from '@/components/XdVideo';
import { mapGetters } from 'vuex';

export default {
    name: 'S5',
    components: {
        Part,
        XdVideo,
    },
    data() {
        return {
            videoOptions: {
                autoplay: true,
                controls: false,
                loop: true,
            },
            skillActiveIndex: 0,
        };
    },
    computed: {
        ...mapGetters(['getImagePath']),
    },
    methods: {
        getInfo(attr) {
            return this.$t(`s5.skills[${this.skillActiveIndex}].${attr}`);
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/mixin';
$space-y: 30px;
.section-5 {
    height: 1561px;
    @include bg(
        $name: 'home/s5/bg.jpg',
        $name-retina: 'home/s5/bg@2x.jpg',
        $size: auto 100%
    );
    .ninja {
        position: absolute;
        width: 978px;
        height: 1145px;
        left: 50%;
        top: 334px;
        margin-left: -18px;
        z-index: 1;

        @include bg(
            $name: 'home/s5/ninja.png',
            $name-retina: 'home/s5/ninja@2x.png',
            $size: auto 100%
        );
    }
    .skills-container {
        position: absolute;
        width: 640px;
        left: 50%;
        top: 270px;
        margin-left: -560px;
        box-sizing: border-box;
        text-align: center;
        z-index: 1;
        > .title {
            font-size: 48px;
            line-height: 60px;
            font-weight: bold;
        }
        .description {
            width: 100%;
            max-width: 460px;
            margin: $space-y auto;
            font-size: 24px;
            line-height: 28px;
        }
        > .icons {
            display: flex;
            justify-content: space-around;
            margin-bottom: $space-y;
            margin-left: -10px;
            margin-right: -10px;
            position: relative;

            > .icon {
                flex: 1 1;
                border-radius: 10px;
                margin: 0 10px;
                width: 0;
                cursor: pointer;
                &.active {
                    box-shadow: 10px 10px 10px rgba(255, 255, 255, 0.8);
                }
                > img{
                    width: 100%;
                    display: block;
                }
            }
        }
        > .skill-active {
            > .video-container {
                position: relative;
                width: 100%;
                border: 15px solid transparent;
                padding-top: 56.25%;
                margin-bottom: $space-y;
                box-sizing: border-box;
                background: #042235;
                /deep/ .web-video-xd {
                    position: absolute;
                    top: 0;
                }
            }
            > .name {
                font-size: 36px;
                line-height: 1;
                margin-bottom: 20px;
                font-weight: bold;
            }
            > .type {
                font-size: 24px;
                line-height: 1;
                margin-bottom: $space-y;
                font-weight: 500;
            }
            > .description {
                font-size: 18px;
                line-height: 21px;
            }
        }
    }
}
@media (max-width: 1248px) {
    .section-5 {
        .skills-container {
            left: 64px;
            margin-left: 0;
        }
    }
}
@media (max-width: 768px) {
    .section-5 {
        box-sizing: border-box;
        padding-top: 988px;
        padding-bottom: 212px;
        box-sizing: border-box;
        height: auto;
        @include bg(
            $name: 'home/s5/bg-768.jpg',
            $name-retina: 'home/s5/bg-768@2x.jpg',
            $size: auto 1114px,
            $color: #203377,
            $y: 90px
        );
        .ninja {
            width: 706px;
            height: 828px;
            top: 150px;
            margin-left: -353px;
        }

        .skills-container {
            position: relative;
            top: auto;
            left: auto;
            width: 83.33%;
            max-width: 640px;
            margin: 0 auto;
            .description {
                max-width: 520px;
            }
        }
    }
}
@media (max-width: 480px) {
    $space-y: 20px;
    .section-5 {
        padding-top: 574px;
        padding-bottom: 150px;
        @include bg(
            $name: 'home/s5/bg-414.jpg',
            $size: auto 498px,
            $color: #203377,
            $y: 0px
        );
        .ninja {
            width: 386px;
            height: 452px;
            top: 98px;
            margin-left: -193px;
        }

        .skills-container {
            .title {
                font-size: 24px;
                line-height: 30px;
            }
            .description {
                max-width: 310px;
                font-size: 12px;
                line-height: 14px;
                margin: $space-y auto;
            }
            .icons {
                margin-bottom: $space-y;
                margin-left: 0;
                margin-right: 0;
                padding: 0 22px;
                > .icon {
                    margin: 0 5px;
                    border-radius: 5px;
                    width: 0;
                    cursor: pointer;
                    height: auto;
                    &.active {
                        box-shadow: 5px 5px 5px rgba(255, 255, 255, 0.8);
                    }
                }
            }
            > .skill-active {
                > .video-container {
                    border-width: 10px;
                    margin-bottom: $space-y;
                }
                > .name {
                    font-size: 24px;
                    margin-bottom: 10px;
                }
                > .type {
                    font-size: 14px;
                    line-height: 1;
                    margin-bottom: $space-y;
                }
                > .description {
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }
    }
}
@media (max-width: 414px) {
    .section-5 {
        @include bg(
            $name: 'home/s5/bg-414.jpg',
            $size: auto 430px,
            $color: #203377,
            $y: 0px
        );
    }
}
</style>
