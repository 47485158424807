// import axios from 'axios';
import { jsonp } from 'vue-jsonp';

// get auth
const tokenUrl = {
    xd: '//www.xd.com/api/xd_video/get_xdvideo',
};
function get_auth_token() {
    return jsonp(tokenUrl.xd)
        .then(function(res) {
            if (res.code === 0) {
                return res.data;
            } else {
                throw -1;
            }
        })
        .catch(function(error) {
            let msg = 'video auth error';
            if (!error.response && error != -1) {
                msg = 'network error';
            }
            throw msg;
        });
}

export { get_auth_token };
