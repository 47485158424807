import Vue from 'vue';
import router from './router';
import App from './App.vue';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import store from './store';
import i18n from './locales';

import 'normalize.css/normalize.css';
import 'swiper/css/swiper.css'

Vue.config.productionTip = false;
Vue.use(VueAwesomeSwiper);
new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
