<template>
    <part class="section-1">
        <locale-dropdown
            :locale="locale"
            :availableLocales="availableLocales"
        ></locale-dropdown>
        <div class="logo"></div>
        <div class="btn-play" @click.stop.prevent="showModal"></div>
        <div class="slogan">
            <img
                :src="getImagePath($t('s1.slogan'))"
                :srcset="getImagePath($t('s1.slogan2x')) + ' 2x'"
            />
        </div>
        <download-group></download-group>
        <div class="modal" v-show="modalVisible">
            <div
                class="modal-overlay"
                @touchmove.prevent
                @click.stop.prevent="hideModal"
            ></div>
            <div class="modal-body">
                <xd-video
                    :vid="$t('s1.vid')"
                    ref="xdvideo"
                    :options="videoOptions"
                    :isPause="!modalVisible"
                ></xd-video>
            </div>
        </div>
    </part>
</template>
<script>
import Part from '@/components/Part.vue';
import LocaleDropdown from '@/components/LocaleDropdown.vue';
import DownloadGroup from '@/components/DownloadGroup';
import XdVideo from '@/components/XdVideo';
import { mapGetters, mapState } from 'vuex';

export default {
    name: 'S1',
    components: {
        Part,
        LocaleDropdown,
        DownloadGroup,
        XdVideo,
    },
    data() {
        return {
            modalVisible: false,
            videoOptions: {
                autoplay: false,
                plugins: {
                    VideoControl: {
                        scrollAutoPlay: false,
                    },
                },
            },
        };
    },
    computed: {
        ...mapState(['locale']),
        ...mapGetters(['availableLocales', 'getImagePath']),
    },

    methods: {
        showModal() {
            this.modalVisible = true;
            document.body.style.overflow = 'hidden';
        },
        async hideModal() {
            this.modalVisible = false;
            document.body.style.overflow = '';
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/mixin';
$space-y: 65px;
.section-1 {
    padding-top: 314px;
    padding-bottom: 314px;
    margin-bottom: -328px;
    box-sizing: border-box;
    @include bg(
        $name: 'home/s1/bg.jpg',
        $name-retina: 'home/s1/bg@2x.jpg',
        $size: auto 1630px
    );
    > .dropdown {
        position: absolute;
        top: 100px;
        right: 50%;

        margin-right: -600px;
    }
    .logo {
        width: 400px;
        height: 250px;
        margin: 0 auto;
        @include bg(
            $name: 'home/s1/logo.png',
            $name-retina: 'home/s1/logo@2x.png',
            $size: 100%
        );
    }
    .btn-play {
        width: 150px;
        height: 104px;
        margin: 0 auto;
        cursor: pointer;
        @include bg(
            $name: 'home/s1/btn-play-video.png',
            $name-retina: 'home/s1/btn-play-video@2x.png',
            $size: 100%
        );
    }
    .slogan {
        width: 650px;
        margin: 0 auto;
        img {
            width: 100%;
            height: 100%;
            display: block;
        }
    }
    .logo,
    .btn-play {
        margin-bottom: $space-y;
    }
}
.modal {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
    align-items: center;
    z-index: 9;
    .modal-overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.6);
    }

    .modal-body {
        position: relative;
        width: 80%;
        height: 50vw;
        max-width: 1200px;
        max-height: 90vh;
        .video-container {
            width: 100%;
            height: 100%;
        }
    }
}
@media (max-width: 1260px) {
    .section-1 {
        > .dropdown {
            top: 50px;
            right: 30px;
            margin-right: 0;
        }
    }
}
@media (max-width: 768px) {
    .section-1 {
        padding-top: 440px;
        padding-bottom: 272px;
        margin-bottom: -202px;
        .logo {
            width: 300px;
            height: 188px;
        }
        .slogan {
            width: 78.125%;
        }
    }
}
@media (max-width: 480px) {
    $space-y: 25px;
    .section-1 {
        min-height: 807px;
        padding-top: 100px;
        margin-bottom: -270px;
        @include bg($name: 'home/s1/bg-414.jpg', $size: cover);
        > .dropdown {
            top: 20px;
            right: 20px;
        }
        .logo {
            width: 200px;
            height: 126px;
        }
        .btn-play {
            width: 102px;
            height: 70px;
        }
        .logo,
        .btn-play {
            margin-bottom: $space-y;
        }
    }
}
</style>
