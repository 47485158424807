export default {
    folder: 'es',
    language: 'Español',
    s1: {
        vid: '3401a1837240e15d5ec8d7c49bf077c1',
        slogan: '@:folder/home/s1/slogan.png',
        slogan2x: '@:folder/home/s1/slogan@2x.png',
    },
    s2: {
        title: 'Un Nuevo Comienzo',
        content:
            'Sumérjase en la nueva aventura de RO 2.0! Inscríbase en la Cryptura Academy, explore el mundo mágico de Eclage y experimente los poderes del sigiloso Ninja.',
    },
    s3: {
        title: '@:folder/home/s3/title.png',
        title2x: '@:folder/home/s3/title@2x.png',
    },
    s4: {
        items: [
            {
                title: '¡Nivel de poder a 120!',
                content: [
                    'Primera parada - Cryptura Academy. ',
                    'Aprenda todo sobre el juego en solo 21 días y el nivel de poder a 120 en solo 30 minutos. ¡Obtenga ítems exclusivos de Cryptura Academy como trajes y mascotas también!',
                ],
            },
            {
                title: 'Desentrañe El Secreto Más Oscuro',
                content: [
                    'Entre bajo su propio riesgo porque los Guardianes no toman la entrada sin autorización a la ligera. Después de todo, el Palacio esconde un profundo y oscuro secreto ...',
                ],
            },
            {
                title: 'Contemplar la Magia',
                content: [
                    'Entre en el misterioso Eclage, hogar de los Pixies y muchas otras criaturas mágicas. ¿Qué glorioso botín encontrarás aquí? ¿Y qué nuevo horror aguarda?',
                ],
            },
        ],
    },
    s5: {
        title: 'Entre el Ninja',
        description:
            '¡Con una variedad de habilidades mortales de ninjutsu, el Ninja es una experiencia de clase de personaje notable que no se debería perder! ',
        skills: [
            {
                name: 'Invocación - Ogare',
                type: 'Habilidad Activa',
                description:
                    'Invoca una Bestia del Trueno a tu alrededor, causando DMG de Viento Mágico de M.ATK *660% cada segundo a 1 unidad enemiga a menos de 6 metros. La Bestia del Trueno dura 9 segundos',
            },
            {
                name: 'Invocación - Uzumaki Maru',
                type: 'Habilidad Activa',
                description:
                    'Invoca una rana ninja para golpear repetidamente todas las unidades dentro del alcance, causando DMG de Magia de Agua de M.ATK *1340% con una probabilidad de 100% de congelar a los objetivos. La rana ninja causa un 50% DMG extra a las unidades enemigas dentro de la Ciénaga.',
            },
            {
                name: 'Ninjutsu - Thousand Shadows',
                type: 'Habilidad Activa',
                description:
                    'Invoca a un Clon Sombrío para ayudarte en la batalla. El Clon Sombrío puede desempeñar algunas habilidades. Cuanto mayor es el nivel de las habilidades, mejores son los atributos del Clon Sombrío. Pueden existir simultáneamente hasta 2 Clones Sombríos y cada uno dura 30 segundos.',
            },
            {
                name: 'Invocación - Manzomaru Abrasador',
                type: 'Habilidad Activa',
                description:
                    'Invoca una Serpiente Yamata bajo tus pies para envolver al enemigo, infligiendo DMG de Magia de Tierra de M.ATK *1160% por segundo en todas las unidades enemigas a menos de 5 metros durante 3 segundos con una probabilidad de 10% de causarles miedo durante 5 segundos',
            },
            {
                name: 'Invocación - Manzomaru Abrasador',
                type: 'Habilidad Activa',
                description:
                    'Invoca al Dragón de Fuego para que ataque el área designada. Después de una pausa, causa M.ATK *2880% de DMG de Daño Mágico de Fuego a enemigos en un radio de 5 metros.',
            },
            {
                name: 'Amaterasu Flash',
                type: 'Habilidad Activa',
                description:
                    'Un Ninja corre hacia adelante 5 metros, causando DMG Físico de ATK 1800% en las unidades enemigas en su camino, con un 28% de probabilidad de hacer sangrar a los enemigos durante 10 segundos. Una Espada debe ser equipada',
            },
        ],
    },
    s6: {
        title: 'Interfaz de usuario (UI) mejorada',
        description:
            '¡La interfaz del juego ha sido renovada para que su experiencia sea mejor que antes!',
    },
    s7: {
        items: [
            {
                title: 'Aumento de Nivel Máximo a 170',
                content:
                    'Sube las apuestas y hágase más fuerte con un nuevo límite de nivel máximo.',
            },
            {
                title: 'Nueva característica: Grind Fuera de Línea',
                content:
                    '¡Ahora incluso puede cultivar para obtener botín y EXP cuando no esté!',
            },
        ],
    },
    s8: {
        content:
            'Cuenta la leyenda que los Dioses prometieron a todas las razas de Midgard que quien alcanzara la cima del Árbol Divino sería bendecido. Muchos intentaron escalar el Árbol Divino, pero solo un grupo de Elfo Flotante tenaces llegó a la cima. Recibieron las bendiciones de los Dioses de longevidad, belleza y dones mágicos innatos y a partir de entonces, construyeron su hogar aquí y lo llamaron Ekalachi.',
    },
    downloadGroup: {
        now: {
            img: '@:folder/home/download-group/btn-download.png',
            img2x: '@:folder/home/download-group/btn-download@2x.png',
            link: {
                SEA: 'https://l.tapdb.net/4zgHlT5z',
                NA: 'https://l.tapdb.net/4zgHlT5z',
                EU: 'https://l.tapdb.net/4zgHlT5z',
            },
        },
        pc: {
            img: '@:folder/home/download-group/btn-pc.png',
            link: {
                SEA: '',
                NA:
                    'https://es.ldplayer.net/games/ragnarok-m-eternal-love-eu-on-pc.html',
            },
        },
        apple: {
            link: {
                SEA: '',
                NA: 'https://apps.apple.com/app/id1444739251',
                EU:
                    'https://apps.apple.com/ae/app/ragnarok-m-eternal-love-eu/id1468396562',
            },
        },
        google: {
            link: {
                SEA: '',
                NA:
                    'https://play.google.com/store/apps/details?id=com.gravity.romNAg',
                EU:
                    'https://play.google.com/store/apps/details?id=com.gravity.romEUg',
            },
        },
        taptap: {
            link: {
                SEA: '',
                NA: 'https://www.taptap.io/app/15537',
                EU: 'https://www.taptap.io/app/175346',
            },
        },
    },
    footer: {
        fb: {
            link: {
                SEA: 'https://www.facebook.com/PlayRagnarokM.EN',
                NA: 'https://www.facebook.com/PlayRagnarokMGlobal.ES/',
                EU: 'https://www.facebook.com/PlayRagnarokMEU.es/',
            },
        },
        ig: {
            link: {
                SEA: 'https://www.instagram.com/RagnarokMOfficial/',
                NA: 'https://www.instagram.com/RagnarokMOfficial/',
                EU: 'https://www.instagram.com/RagnarokMOfficial/',
            },
        },
        yt: {
            link: {
                SEA: 'https://www.youtube.com/c/RagnarokMEternalLoveOfficial',
                NA: 'https://www.youtube.com/c/RagnarokMEternalLoveOfficial',
                EU: 'https://www.youtube.com/c/RagnarokMEternalLoveOfficial',
            },
        },
        service: {
            text: 'Términos de Servicio',
        },
        privacy: {
            text: 'Política de Privacidad',
        },
        notice: {
            text: 'Privacy Notice for California Residents',
        },
        cookies: {
            text: 'Cookies Policy',
        },
        terms: {
            text: 'Terms of Service (For EU Residents)',
        },
        policy: {
            text: 'Privacy Policy  (For EEA and UK Residents)',
        },
    },
};
