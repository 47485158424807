export default {
    folder: 'tr',
    language: 'Türkçe',
    s1: {
        vid: 'c97493caeb4a0e7b407fdcae085b5af8',
        slogan: '@:folder/home/s1/slogan.png',
        slogan2x: '@:folder/home/s1/slogan@2x.png',
    },
    s2: {
        title: 'Yeni Bir Başlangıç',
        content:
            "RO 2.0’da yeni bir maceraya katıl! Cryptura Akademisine kayıt ol, Eclage'ın büyülü dünyasını keşfederken Sinsi Ninja'nın güçlerini deneyimle.",
    },
    s3: {
        title: '@:folder/home/s3/title.png',
        title2x: '@:folder/home/s3/title@2x.png',
    },
    s4: {
        items: [
            {
                title: '120’ye Kadar Güç Seviyesi',
                content: [
                    'İlk Durak Cryptura Akademisi ',
                    'Oyun hakkında herşeyi sadece 21 günde öğrenecek 30 dakikada 120 seviyeye ulaş.Ayrıcalıklı Cryptura Akademisi kostümü, evcil haycanı vb malzemeleri hemen kazan!',
                ],
            },
            {
                title: 'Karanlık Gizemi Aydınlat',
                content: [
                    'Muhafızlar izinsiz girişleri onaylamadığı için tüm risk senin üzerinde. Saraydaki büyük ve karanlık gizemi çözebilecek misin?',
                ],
            },
            {
                title: 'Büyülü Dünyada Sihirlerini hazırla',
                content: [
                    'Perilerin ve büyülü yaratıkların yaşadığı gizemli Eclage Dünyasına katıl. Gizem perdesini aralarken perilerin sakladığı hazineleri bul!',
                ],
            },
        ],
    },
    s5: {
        title: 'Ninja Sınıfı',
        description:
            'Bir dizi ölümcül ninjutsu becerisiyle Ninja, kaçırmak istemeyeceğiniz olağanüstü bir karakter sınıfıdır! ',
        skills: [
            {
                name: 'Çağırma - Ogare',
                type: 'Aktif Beceri',
                description:
                    "Her saniye 6 metre içindeki 1 düşman birime B.ATK *%660 Rüzgar Büyüsü HSR'ı vererek etrafını bir Kasırga Canavarı ile çevrele. Kasırga canavarı 9 saniye sürer",
            },
            {
                name: 'Çağırma - Uzumaki Maru',
                type: 'Aktif Beceri',
                description:
                    "Menzil içindeki tüm düşman birimlerine art arda vurmak için bir ninja kurbağa çağır, B.ATK *%1340 Su Büyüsü HSR'ı ve hedefleri %100 dondurma şansı verir. Ninja kurbağalar, Marsh'taki düşman birimlere ekstra %50 HASAR verir",
            },
            {
                name: 'Ninjutsu - Bin Gölge',
                type: 'Aktif Beceri',
                description:
                    'Savaşta yardımcı olması için bir Gölge Klonu çağır. Gölge Klon bazı beceriler kullanılacak. Beceri seviyesi ne kadar yüksek olursa, Gölge Klonu’n özellikleri de o kadar iyi olur. Maks 2 Gölge Klona kadar çağırabilirsin ve her biri 20 saniye kalır.',
            },
            {
                name: 'Çağırma - Yılan',
                type: 'Aktif Beceri',
                description:
                    "Düşmanın etrafını sarmak için bir Yamata Serpent çağır, 5 saniye boyunca 5 metre/saniye içindeki tüm düşman birimlerine B.ATK *%11660 Toprak Büyüsü HSR'ı ver ve %10 şansla 5 saniye boyunca korkut.",
            },
            {
                name: 'Çağırma - Kavurucu Manzomaru',
                type: 'Aktif Beceri',
                description:
                    "Belirlenen alana saldırmak için Ateş Ejderhası çağır. Bir duraklamadan sonra, 5 metre içindeki düşmanlara B.ATK *%2880 Ateş Büyüsü HSR'ı ver.",
            },
            {
                name: 'Amaterasu Flaş',
                type: 'Aktif Beceri',
                description:
                    'Bir Ninja 5 metre ileri atılarak, önündeki düşman birimlerine ATK *%1800 Fiziksel HSR verir ve %28 şansla hedeflerin 10 saniye boyunca kanamasına neden olur. Bir Kılıç kullanılmalı.',
            },
        ],
    },
    s6: {
        title: 'Geliştirilmiş UI',
        description:
            'Oyun arayüzü, daha iyi bir deneyim sağlamak için iyileştirildi!',
    },
    s7: {
        items: [
            {
                title: 'Maks Seviye 170’e Yükseltildi',
                content:
                    'Maks seviye artışı ile sınırları ortadan kaldır daha fazla güçlen.',
            },
            {
                title: 'Yeni Özellik: Çevrimdışı Oyun',
                content:
                    'Oyunda değilken bile artık EXP kasabilir, kaynak kasabilirsiniz.',
            },
        ],
    },
    s8: {
        content:
            "Efsaneye göre, Midgard'daki tüm ırklara, İlahi Ağacın zirvesine ulaştıklarında kutsanacakları sözü verildi. İlahi Ağaca doğru çok kişi ulaşmaya denese de bir grup inatçı periden başkası ulaşamadı. Tanrıların uzun ömür, güzellik ve doğuştan gelen sihirli armağanlar kutsamasını aldılar ve daha sonra burada evlerini inşa ettiler ve buraya Eclage adını verdiler.",
    },
    downloadGroup: {
        now: {
            img: '@:folder/home/download-group/btn-download.png',
            img2x: '@:folder/home/download-group/btn-download@2x.png',
            link: {
                SEA: '',
                NA: '',
                EU: '',
            },
        },
        pc: {
            img: '@:folder/home/download-group/btn-pc.png',
            link: {
                SEA:
                    'https://www.ldplayer.net/games/ragnarok-m-eternal-love-eu-on-pc.html',
                NA:
                    'https://www.ldplayer.net/games/ragnarok-m-eternal-love-eu-on-pc.html',
                EU:
                    'https://www.ldplayer.net/games/ragnarok-m-eternal-love-eu-on-pc.html',
            },
        },
        apple: {
            link: {
                SEA:
                    'https://apps.apple.com/ae/app/ragnarok-m-eternal-love-eu/id1468396562',
                NA:
                    'https://apps.apple.com/ae/app/ragnarok-m-eternal-love-eu/id1468396562',
                EU:
                    'https://apps.apple.com/ae/app/ragnarok-m-eternal-love-eu/id1468396562',
            },
        },
        google: {
            link: {
                SEA:
                    'https://play.google.com/store/apps/details?id=com.gravity.romEUg',
                NA:
                    'https://play.google.com/store/apps/details?id=com.gravity.romEUg',
                EU:
                    'https://play.google.com/store/apps/details?id=com.gravity.romEUg',
            },
        },
        taptap: {
            link: {
                SEA: 'https://www.taptap.io/app/175346',
                NA: 'https://www.taptap.io/app/175346',
                EU: 'https://www.taptap.io/app/175346',
            },
        },
    },
    footer: {
        fb: {
            link: {
                SEA: 'https://www.facebook.com/PlayRagnarokMEU.tr/',
                NA: 'https://www.facebook.com/PlayRagnarokMEU.tr/',
                EU: 'https://www.facebook.com/PlayRagnarokMEU.tr/',
            },
        },
        ig: {
            link: {
                SEA: 'https://www.instagram.com/RagnarokMOfficial/',
                NA: 'https://www.instagram.com/RagnarokMOfficial/',
                EU: 'https://www.instagram.com/RagnarokMOfficial/',
            },
        },
        yt: {
            link: {
                SEA: 'https://www.youtube.com/c/RagnarokMEternalLoveOfficial',
                NA: 'https://www.youtube.com/c/RagnarokMEternalLoveOfficial',
                EU: 'https://www.youtube.com/c/RagnarokMEternalLoveOfficial',
            },
        },
        service: {
            text: 'Kullanım Şartları',
        },
        privacy: {
            text: 'Gizlilik Politikası',
        },
        notice: {
            text: 'California Sakinleri için Gizlilik Bildirimi',
        },
        cookies: {
            text: 'Çerezler Politikası',
        },
        terms: {
            text: 'Hizmet Sözleşmesi (Avrupa Vatandaşları için)',
        },
        policy: {
            text: 'Gizlilik Sözleşmesi (Avrupa ve İngiliz Vatandaşları için)',
        },
    },
};
