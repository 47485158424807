import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './en';
import th from './th';
import id from './id';
import zh from './zh';
import pt from './pt';
import es from './es';
import es_eu from './es_eu';
import tr from './tr';
import de from './de';
import ru from './ru';
import fr from './fr';

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    // formatFallbackMessages: true,
    messages: {
        en, // 英文语言包
        th, // 泰文语言包
        id, // 印尼文语言包
        zh, // 中文
        pt, // 葡萄牙
        es, // 北美地区西班牙语
        es_eu, // 欧洲地区西班牙语
        tr, //
        de,
        ru,
        fr,
    },
});

export default i18n;
