import { get_auth_token } from '../api/xdvideo';

const xdvideo = {
    namespaced: true,
    state: {
        auth: {
            app_id: '',
            sign: '',
            noncestr: '',
            timestamp: '',
        },
        status: false,
        loading: false,
    },
    getters: {},
    mutations: {
        updateAuth: (state, { status, auth }) => {
            state.auth = { ...auth };
            state.status = status;
        },
        updateLoading: (state, payload) => {
            state.loading = payload;
        },
    },
    actions: {
        async asyncGetToken({ commit, state }) {
            try {
                if (!state.status && !state.loading) {
                    commit('updateLoading', true);
                    let {
                        app_id,
                        noncestr,
                        sign,
                        timestamp,
                    } = await get_auth_token();
                    commit('updateAuth', {
                        auth: { app_id, noncestr, sign, timestamp },
                        status: true,
                    });
                }
            } catch (error) {
                commit('updateAuth', { status: false, auth: {} });
                console.error('get video auth error:', error);
            }
            commit('updateLoading', false);
        },
    },
};
export default xdvideo;
