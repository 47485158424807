export default {
    folder: 'ru',
    language: 'Русский',
    s1: {
        vid: '3c165840ce82a6362a72d39a7a8575c8',
        slogan: '@:folder/home/s1/slogan.png',
        slogan2x: '@:folder/home/s1/slogan@2x.png',
    },
    s2: {
        title: 'Новое начало',
        content:
            'Окунитесь в новое приключение RO 2.0! Запишитесь в Имперскую Академию Криатуры, исследуйте волшебный мир Экалачи и испытайте силы тайного ниндзя.',
    },
    s3: {
        title: '@:folder/home/s3/title.png',
        title2x: '@:folder/home/s3/title@2x.png',
    },
    s4: {
        items: [
            {
                title: 'Уровень мощности до 120!',
                content: [
                    '"Первая остановка - Имперская Академия Криатуры.',
                    'Узнайте все об игре всего за 21 день, а уровень мощности до 120 всего за 30 минут. Получите эксклюзивные предметы Имперской Академии Криатуры, такие как костюмы и домашние животные!"',
                ],
            },
            {
                title: 'Раскройте самый мрачный секрет',
                content: [
                    'Входите на свой страх и риск, потому что Стражи не относятся легкомысленно к вторжению. В конце концов, Дворец скрывает глубокую, темную тайну ...',
                ],
            },
            {
                title: 'Узри магию',
                content: [
                    'Войдите в таинственный Экалачи, дом пикси и многих других волшебных существ.Какую славную добычу вы здесь найдете? А какой новый ужас ждет вас?',
                ],
            },
        ],
    },
    s5: {
        title: 'Введите ниндзя',
        description:
            'Обладая целым рядом смертоносных навыков ниндзюцу, Ниндзя - это замечательный класс для персонажей, который вы не захотите пропустить! ',
        skills: [
            {
                name: 'Призыв - Огаре',
                type: 'Активный навык',
                description:
                    'Призови к себе Зверя грома, который будет каждую секунду наносить магический ветряной  урон от Mаг. атаки *660%  по одному врагу в радиусе 6 метров. Зверь грома будет действовать в течение 9 секунд',
            },
            {
                name: 'Призыв - Узумаки Мару',
                type: 'Активный навык',
                description:
                    'Призывает лягушку-ниндзя, которая многократно наносит удары по всем вражеским юнитам в пределах досягаемости, нанося Mаг. атаку * 1340% Урон от Магии Воды с вероятностью 100% замораживания целей. Лягушки-ниндзя наносят дополнительный урон на 50% вражеским юнитам на Марсе.',
            },
            {
                name: 'Ниндзюцу - Тысяча теней',
                type: 'Активный навык',
                description:
                    'Призывает одного Теневого клона для помощи в битве. Теневой клон может применять некоторые навыки. Чем выше уровень навыка, тем лучше атрибуты Теневого клона. Одновременное может существовать до 2 Теневых клонов, каждый из которых существует 20 секунд',
            },
            {
                name: 'Призыв - Змей',
                type: 'Активный навык',
                description:
                    'Призови к своим ногам змея Ямату, чтобы поймать в ловушку окружающих врагов и каждую секунду наносить земляной магический урон от Mаг. атаки  *1160% по всем врагам в радиусе 5 метров продолжительностью 3 секунды с 10% шансом ввергнуть их в страх на 5 секунд.',
            },
            {
                name: 'Призыв - Опаляющий Манзомару',
                type: 'Активный навык',
                description:
                    'Призовите Огненного Дракона, чтобы атаковать указанную область. После паузы нанесите Mаг. атаку * 2880% урона Магии Огня противникам в радиусе 5 метров.',
            },
            {
                name: 'Аматэрасу Флэш',
                type: 'Активный навык',
                description:
                    'Ниндзя бросается на 5 метров вперед, нанося Атаку * 1800% физического урона вражеским юнитам на своем пути с 28% шансом вызвать кровотечение у целей в течение 10 секунд. Меч должен быть экипирован.',
            },
        ],
    },
    s6: {
        title: 'Улучшенный интерфейс',
        description:
            'Интерфейс игры был переработан, чтобы вам было удобнее, чем раньше!',
    },
    s7: {
        items: [
            {
                title: 'Максимальный уровень увеличен до 170',
                content:
                    'Повышайте ставки и становитесь сильнее с новым максимальным уровнем.',
            },
            {
                title: 'Новая функция: Оффлайн Гринд',
                content:
                    'Теперь вы можете даже фармить добычу и опыт, когда идете в АФК!',
            },
        ],
    },
    s8: {
        content:
            'Легенда гласит, что боги пообещали всем расам Мидгарда, что тот, кто достигнет пика Священного дерева, будет благословлен. Многие пытались взобраться на Священное дерево, но только группа стойких пикси добралась до вершины. Они получили благословения богов долголетия, красоты и врожденных магических даров, и после этого построили здесь свой дом, назвав его Экалачи.',
    },
    downloadGroup: {
        now: {
            img: '@:folder/home/download-group/btn-download.png',
            img2x: '@:folder/home/download-group/btn-download@2x.png',
            link: {
                SEA: '',
                NA: '',
                EU: '',
            },
        },
        pc: {
            img: '@:folder/home/download-group/btn-pc.png',
            link: {
                SEA:
                    'https://www.ldplayer.net/games/ragnarok-m-eternal-love-eu-on-pc.html',
                NA:
                    'https://www.ldplayer.net/games/ragnarok-m-eternal-love-eu-on-pc.html',
                EU:
                    'https://www.ldplayer.net/games/ragnarok-m-eternal-love-eu-on-pc.html',
            },
        },
        apple: {
            link: {
                SEA:
                    'https://apps.apple.com/ae/app/ragnarok-m-eternal-love-eu/id1468396562',
                NA:
                    'https://apps.apple.com/ae/app/ragnarok-m-eternal-love-eu/id1468396562',
                EU:
                    'https://apps.apple.com/ae/app/ragnarok-m-eternal-love-eu/id1468396562',
            },
        },
        google: {
            link: {
                SEA:
                    'https://play.google.com/store/apps/details?id=com.gravity.romEUg',
                NA:
                    'https://play.google.com/store/apps/details?id=com.gravity.romEUg',
                EU:
                    'https://play.google.com/store/apps/details?id=com.gravity.romEUg',
            },
        },
        taptap: {
            link: {
                SEA: 'https://www.taptap.io/app/175346',
                NA: 'https://www.taptap.io/app/175346',
                EU: 'https://www.taptap.io/app/175346',
            },
        },
    },
    footer: {
        fb: {
            link: {
                SEA: 'https://www.facebook.com/PlayRagnarokMEU.ru/',
                NA: 'https://www.facebook.com/PlayRagnarokMEU.ru/',
                EU: 'https://www.facebook.com/PlayRagnarokMEU.ru/',
            },
        },
        ig: {
            link: {
                SEA: 'https://www.instagram.com/RagnarokMOfficial/',
                NA: 'https://www.instagram.com/RagnarokMOfficial/',
                EU: 'https://www.instagram.com/RagnarokMOfficial/',
            },
        },
        yt: {
            link: {
                SEA: 'https://www.youtube.com/c/RagnarokMEternalLoveOfficial',
                NA: 'https://www.youtube.com/c/RagnarokMEternalLoveOfficial',
                EU: 'https://www.youtube.com/c/RagnarokMEternalLoveOfficial',
            },
        },
        service: {
            text: 'Условия использования',
        },
        privacy: {
            text: 'Политика конфиденциальности',
        },
        notice: {
            text: 'Уведомление о конфиденциальности для резидентов Калифорнии',
        },
        cookies: {
            text: 'Политика использования файлов «cookie»',
        },
        terms: {
            text: 'Условия обслуживания (для резидентов ЕС)',
        },
        policy: {
            text: 'Политика конфиденциальности (для жителей ЕЭЗ и Великобритании)',
        },
    },
};
