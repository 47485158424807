<template>
    <div class="main">
        <S1 />
        <S2 />
        <S3 />
        <S4 />
        <S5 />
        <S6 />
        <S7 />
        <S8 />
        <Footer></Footer>
    </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import S1 from './Home/S1.vue';
import S2 from './Home/S2.vue';
import S3 from './Home/S3.vue';
import S4 from './Home/S4.vue';
import S5 from './Home/S5.vue';
import S6 from './Home/S6.vue';
import S7 from './Home/S7.vue';
import S8 from './Home/S8.vue';
import Footer from './Home/Footer.vue';

export default {
    name: 'Home',
    components: {
        S1,
        S2,
        S3,
        S4,
        S5,
        S6,
        S7,
        S8,
        Footer,
    },
    data() {
        return {};
    },
    computed: {
        ...mapState(['locale']),
        ...mapGetters(['path']),
    },
    methods: {
        // 根据路由更新当前语言，检测是否是支持的语言，如果不支持则重定向
        async updateLocale(params) {
            let { path } = params;
            if (path !== this.path) {
                await this.$store.dispatch('setLocale', path);
                if (path !== this.path) {
                    return true;
                }
            }
            return false;
        },
    },
    async created() {
        let needRedirect = await this.updateLocale(this.$route.params);
        if (needRedirect) {
            this.$router.push({
                name: 'home',
                params: { ...this.$route.params, path: this.path },
            });
        }
    },
    async beforeRouteUpdate(to, from, next) {
        if (!(await this.updateLocale(to.params))) {
            next();
        } else {
            next({
                name: 'home',
                params: { ...to.params, path: this.path },
            });
        }
    },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/mixin';
.main {
    position: relative;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    overflow: hidden;
    color: white;
}
</style>
